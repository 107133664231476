// import React, { useState, useEffect } from "react";
// import './rating.css';
// // import nose from '../assets/assest/nose.png';
// import nose from '../assets/assest/on_the_nose.png'
// // import palate from '../assets/assest/palate.png'
// import palate from '../assets/assest/on_the_palate.png'
// import { useLocation,useNavigate } from "react-router-dom";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faStar } from '@fortawesome/free-solid-svg-icons';
// import backbutton from '../../src/assets/assest/back.jpeg'
// import Modal from 'react-modal';
// import Loader from "./loader/loader";

// const Rating = () => {

//   const [apidata,setApiData]= useState(null);
//   const [modalOpen, setModalOpen] = useState(false);
//   const navigate = useNavigate();
//   const [rating,setRating]=useState(0);
//   const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
//   const location = useLocation();
//   const { report_id } = location.state || {};
//   const [isLoading, setIsLoading] = useState(true);
//   const [showPopup, setShowPopup] = useState(false);
//   const [popupData, setPopupData] = useState([]);
//   const [popupTitle, setPopupTitle] = useState('');

//   const closeModal = () => {
//     setModalOpen(false);
//   };
//   const openModal = () => {
//     setModalOpen(true);
//   };
//   const formData = new FormData(); 
//   if(localStorage.getItem('isActive')=='0')
//     {
//       formData.append('data',localStorage.getItem('on_the_nose'));
//     }
//     if(localStorage.getItem('isActive')=='1')
//       {
//         formData.append('data',localStorage.getItem('on_the_palate'));
//       }

//   useEffect(() => {
//     const token = localStorage.getItem('token');
//     fetch('https://admin.smacflavourfolio.com/api/user/getSubCategoryImages', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `Bearer ${token}`,
//       },
//        body: JSON.stringify({ datas:formData.get('data') }),
//     })
    
//       .then(response => response.json())
//       .then(data => {setApiData(data)
//       console.log("rating page data",data);})
      
//       .catch(error => console.error('Error fetching data:', error));
     
  
//     if (localStorage.getItem('isActive') == 0) {
//       if (localStorage.getItem('on_the_nose_rating') != null) {
//         setRating(localStorage.getItem('on_the_nose_rating'));
//       }
//     } else {
//       if (localStorage.getItem('on_the_palate_ratings') != null) {
//         setRating(localStorage.getItem('on_the_palate_ratings'));
//       }
//     }
//   }, []);
  
  
  
//   const selectedRating=(number)=>{
//     console.log('number',number);
//      setRating(number);
//   }

//   const submitRating=()=>{
//     if(localStorage.getItem('isActive')=='0')
//     {
//       localStorage.setItem('is_on_the_nose','1');
//       localStorage.setItem('on_the_nose_rating',rating);
//       navigate('/mainpage');
//     }
//     else
//     {
//     if(localStorage.getItem('isActive')=='1')
//      {
//       localStorage.setItem('is_on_the_palate','1');
//       localStorage.setItem('on_the_palate_ratings',rating);
//      }
//      if(localStorage.getItem('isActive')=='2')
//      {
//       localStorage.setItem('finish','1');
//       localStorage.setItem('rating',rating);
//      }
//      navigate('/mainpage');
//     }
//   }

//   const handleGoBack = () => {
//     openModal();
//     // window.history.back();
//   };
//   const handleBack = () => {
//     window.history.back();
//   };
//   const togglePopup = (data, title) => {
//     setPopupData(data);
//     setPopupTitle(title);
//     setShowPopup(!showPopup);
//   }
//   return (
//     <>
//     {/* {isLoading ? ( 
//         <Loader />
//       ) : ( */}
//     <div className="dashboardn-background p-4">
//       <div style={{padding:'10px'}}>
//       <div>
//               <button
//                 type="button" 
//                 className="btn-link"
//                 style={{ color: "black",border:'none',background:'transparent',cursor:'pointer',marginTop:'1px',marginLeft:'-5px' }}
//                  onClick={()=>handleGoBack()}
//                 >
//                   <div className='gradient-border'>
//                 <img src={backbutton} style={{cursor:'pointer',width:'50px',height:'50px',borderRadius:'13px'}}/>
//               </div>
//               </button>
//             </div>
        
//         {/* <div>
//           {localStorage.getItem('isActive')=="0"?(<img className="mt-5 mx-3 " alt="" src={nose} style={{width:'100px'}}/>):
//           (<img className="mt-5 mx-3" alt="" src={palate} style={{width:'100px'}}/>)
//           }

//         </div> */}
//         <div className="content-container1">
//   {localStorage.getItem('isActive') === "0" ? (
//     <div>
//     <>
//       <h1 className="title1">AROMATICS</h1>
//       <img  alt="nose" src={nose} style={{width:'175px',marginBottom:'10px'}}/>
//     </>
//     <div className="ardmore" style={{ display: 'flex',marginBottom:'20px',height:'170px',width:"343px" }}>
//     <div className="nose-palate-section" style={{ marginRight: '10px' ,fontSize:'22px'}}>
//       <div className="nose-container" style={{ borderRadius: '10px', marginBottom: '20px',width:'30%' }}>
//        <p className="left-text">AROMAS IDENTIFIED</p>
//       </div>
      
//     </div>
//   <div className="images-section" style={{width:'91%'}}>
//       <div className="flavours">
//         <div className="circular-layout">
//           <div className="im-container" style={{ display: 'flex', alignItems: 'center' }}>
//             <div className="row-container" style={{ display: 'flex', flexDirection: 'column' }}>
//               <div className="im-container1">
//                 {apidata && (
//                   <div className="image-grid" style={{ gridGap: '4px' }}>
//                     {apidata.data.map((aroma, index) => (
//                       <div className="small-image-container" key={index}>
//                         <img src={aroma.icon.url} alt={`On the Nose Image ${index + 1}`} className="small-image" />
//                       </div>
//                     ))}
//                     {/* {apidata.data.onthenose.length < 6 && apidata.data.randomonthenose.slice(0, Math.max(0, 6 - apidata.data.onthenose.length)).map((aroma, index) => (
//                       <div className="small-image-container" key={index + apidata.data.onthenose.length}>
//                         <img src={aroma.icon.url} alt={`On the Nose Image ${index + apidata.data.onthenose.length + 1}`} className="small-image"/>
//                       </div>
//                     ))} */}
//                   </div>
//                 )}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//     </div>
//     </div>
//   ) : (
//     <div>
//   <>
//     <h1 className="title1">THE PALATE / FLAVOUR</h1>
//     <img alt="palate" src={palate} style={{ width: '175px', marginBottom: '20px' }} />
//   </>
//   <div className="ardmore" style={{ display: 'flex', marginBottom: '20px', height: '170px', width: '343px' }}>
//     <div className="nose-palate-section" style={{ marginRight: '10px', fontSize: '22px' }}>
//       <div className="nose-container" style={{ borderRadius: '10px', marginBottom: '20px', width: '30%' }}>
//         <p className="left-text">FLAVOURS IDENTIFIED</p>
//       </div>
//     </div>
//     <div className="images-section" style={{width:'91%'}}>
//     <div className="flavours">
//       <div className="circular-layout">
//         <div className="im-container" style={{ display: 'flex', alignItems: 'center' }}>
//           <div className="row-container" style={{ display: 'flex', flexDirection: 'column' }}>
//             {/* <div className="im-container1">
//               {apidata && (
//                 <div className="image-grid" style={{ gridGap: '4px' }}>
//                   {apidata.data.onthepalate.slice(0, 6).map((aroma, index) => (
//                     <div className="small-image-container" key={index}>
//                       <img src={aroma.icon.url} alt={`On the Palate Image ${index + 1}`} className="small-image"  />
//                     </div>
//                   ))}
//                   {apidata.data.onthepalate.length < 6 && apidata.data.randomonthepalate.slice(0, Math.max(0, 6 - apidata.data.onthepalate.length)).map((aroma, index) => (
//                     <div className="small-image-container" key={index + apidata.data.onthepalate.length}>
//                       <img src={aroma.icon.url} alt={`On the Palate Image ${index + apidata.data.onthepalate.length + 1}`} className="small-image" />
//                     </div>
//                   ))}
//                 </div>
//               )}
//             </div> */}
//             <div className="im-container1">
//   {apidata && apidata.data && (
//     <div className="image-grid" style={{ gridGap: '4px' }}>
//      {apidata && (
//                   <div className="image-grid" style={{ gridGap: '4px' }}>
//                     {apidata.data.map((aroma, index) => (
//                       <div className="small-image-container" key={index}>
//                         <img src={aroma.icon.url} alt={`On the Nose Image ${index + 1}`} className="small-image" />
//                       </div>
//                     ))}
//                     {/* {apidata.data.onthenose.length < 6 && apidata.data.randomonthenose.slice(0, Math.max(0, 6 - apidata.data.onthenose.length)).map((aroma, index) => (
//                       <div className="small-image-container" key={index + apidata.data.onthenose.length}>
//                         <img src={aroma.icon.url} alt={`On the Nose Image ${index + apidata.data.onthenose.length + 1}`} className="small-image"/>
//                       </div>
//                     ))} */}
//                   </div>
//                 )}
//       {/* {apidata.data.onthepalate && apidata.data.onthepalate.length < 6 && Array.isArray(apidata.data.randomonthepalate) && apidata.data.randomonthepalate.slice(0, Math.max(0, 6 - apidata.data.onthepalate.length)).map((aroma, index) => (
//         <div className="small-image-container" key={index + apidata.data.onthepalate.length}>
//           <img src={aroma.icon.url} alt={`On the Palate Image ${index + apidata.data.onthepalate.length + 1}`} className="small-image" />
//         </div>
//       ))} */}
//     </div>
//   )}
// </div>

//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
// </div>

//   )}
// </div>


//         {/* <p>WRITE A REVIEW</p>
//         <p>Any aromas that you wish to capture apart from the above?</p> */}
//         {localStorage.getItem('isActive')=="0"?(
//          <h1 className="title1">RATE THE AROMATICS</h1>
//           // <p>Rate the whisky's aroma from 1 (poor) to 10 (excellent)</p>
//         ):
//           (
//             <p className="title1">RATE THE PALATE</p>
//           // <p>Rate the whisky's taste from 1 (poor) to 10 (excellent)</p>
//           )
//         }
//         <div className='d-flex justify-content-center'>
//         {numbers.map((number) => (
//         <div class="text-center" style={{paddingRight:'5px'}}>
//         <FontAwesomeIcon icon={faStar} className={`fas fa-star ${rating>=number ? 'myselected' : ''}`} style={{fontSize:'22px'}}  onClick={()=>selectedRating(number)}/>
//         <br/>
//         <label>{number}</label>
//         </div>
//         ))}
//         </div>
//         <br/>
//         {/* <div className="form-floating input-shadow mb-3">
//                 <textarea
//                   className="form-control"
//                   placeholder="Leave a comment here"
//                   id="floatingTextarea2"
//                   style={{ height: "100px" }}
//                 ></textarea>
//                 <label for="floatingTextarea2">Comment Here</label>
//         </div> */}

//         <div className='d-flex justify-content-center' >
//         <div className='gradient-border'>
//         <button className="smacid-btn"
//         onClick={() => submitRating()}
//          style={{ width:'123px',height:'47px',backgroundColor:'#0A253C',alignItems:'center'}} >
//           <span><b style={{fontSize:'16px',}}>SUBMIT</b></span>
//           </button>
//         {/* <button class=" smacid-btn " onClick={() => submitRating()} style={{backgroundColor:'#0A253C'}}>
//             <strong>SUBMIT</strong>
//           </button> */}
//           </div>
//         </div>
//       </div>
//       <Modal
//   isOpen={modalOpen}
//   onRequestClose={closeModal}
//   contentLabel="Example Modal"
//   style={{
//     content: {
//       height: '157px', 
//       margin: 'auto',
//       width: '80%', 
//       textAlign: 'center' 
//     }
//   }}
// >
//   <div>
//     <p>Please submit your feedback by clicking the submit button...</p>
//     {/* <p>Click 'Submit' to unleash your personalized AI tasting notes! If you want to escape this flavor adventure, just click outside. 🌈🍹</p> */}
//     <button style={{ float: 'right', backgroundColor: '#0A253C' }} className="btn btn-success" onClick={() => navigate('/rating')}>Submit</button>
//     <button style={{ float: 'left', backgroundColor: '#0A253C' }} className="btn btn-primary" onClick={() =>handleBack()}>Go back</button>
//   </div>
// </Modal>
// <Modal
//           isOpen={showPopup}
//           onRequestClose={() => setShowPopup(false)}
//           contentLabel="View All Popup"
//           style={{
//             content: {
//               height: 'fit-content',
//               width: '80%',
//               margin: 'auto',
//               overflow: 'auto'
//             }
//           }}
//         >
          
//           {/* <h2>{popupTitle}</h2> */}
//           <div className="popup-grid">
//             {popupData.map((item, index) => (
//               <div key={index} className="popup-item">
//                 <img src={item.icon.url} alt={item.name} className="popup-image" />
//                 <p>{item.name}</p>
//               </div>
//             ))}
//           </div>
//           <button onClick={() => setShowPopup(false)} className="btn btn-primary" style={{ backgroundColor: '#0A253C',float:'right'}}>Close</button>
//         </Modal>
//     </div>
      
//     </>
        
//   );
// };
// export default Rating;

import React, { useState, useEffect } from "react";
import './rating.css';
import nose from '../assets/assest/on_the_nose.png';
import palate from '../assets/assest/on_the_palate.png';
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import backbutton from '../../src/assets/assest/back.jpeg';
import Modal from 'react-modal';
import Loader from "./loader/loader";

const Rating = () => {
  const [apidata, setApiData] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const [rating, setRating] = useState(0);
  const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const location = useLocation();
  const { report_id } = location.state || {};
  const [isLoading, setIsLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState([]);
  const [popupTitle, setPopupTitle] = useState('');
  const closeModal = () => setModalOpen(false);
  const openModal = () => setModalOpen(true);

  const formData = new FormData(); 
  if (localStorage.getItem('isActive') === '0') {
    formData.append('data', localStorage.getItem('on_the_nose'));
  }
  if (localStorage.getItem('isActive') === '1') {
    formData.append('data', localStorage.getItem('on_the_palate'));
  }

  useEffect(() => {
    setIsLoading(true);
    const token = localStorage.getItem('token');
    fetch('https://admin.smacflavourfolio.com/api/user/getSubCategoryImages', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({ datas: formData.get('data') }),
    })
      .then(response => response.json())
      .then(data => {
        setApiData(data);
        setIsLoading(false);
        console.log("rating page data", data);
      })
      .catch(error => console.error('Error fetching data:', error));
    
    if (localStorage.getItem('isActive') === '0') {
      if (localStorage.getItem('on_the_nose_rating') !== null) {
        setRating(localStorage.getItem('on_the_nose_rating'));
      }
    } else {
      if (localStorage.getItem('on_the_palate_ratings') !== null) {
        setRating(localStorage.getItem('on_the_palate_ratings'));
      }
    }
  }, []);

  const selectedRating = (number) => {
    console.log('number', number);
    setRating(number);
  };

  const submitRating = () => {
    if (localStorage.getItem('isActive') === '0') {
      localStorage.setItem('is_on_the_nose', '1');
      localStorage.setItem('on_the_nose_rating', rating);
      navigate('/mainpage');
    } else if (localStorage.getItem('isActive') === '1') {
      localStorage.setItem('is_on_the_palate', '1');
      localStorage.setItem('on_the_palate_ratings', rating);
      navigate('/mainpage');
    } else if (localStorage.getItem('isActive') === '2') {
      localStorage.setItem('finish', '1');
      localStorage.setItem('rating', rating);
      navigate('/mainpage');
    }
  };

  const handleGoBack = () => openModal();
  const handleBack = () => window.history.back();

  const togglePopup = (data, title) => {
    setPopupData(data);
    setPopupTitle(title);
    setShowPopup(!showPopup);
  };

  const getImages = () => {
    if (!apidata || !apidata.data) return [];
    return showPopup ? apidata.data : apidata.data.slice(0, 6);
  };

  return (
    <>
      <div className="dashboardn-background p-2">
      {isLoading ? (
                                    <Loader />
                                ) : <div>
          <div>
            <button
              type="button" 
              className="btn-link"
              style={{ color: "black", border: 'none', background: 'transparent', cursor: 'pointer', marginTop: '1px', marginLeft: '-5px' }}
              onClick={handleGoBack}
            >
              <div className='gradient-border'>
                <img src={backbutton} style={{ cursor: 'pointer', width: '50px', height: '50px', borderRadius: '13px' }} alt="Back Button"/>
              </div>
            </button>
          </div>

        <div className="content-container1">
            {localStorage.getItem('isActive') === "0" ? (
              <div>
                <>
                  <h1 className="title1">AROMATICS</h1>
                  <img alt="nose" src={nose} style={{ width: '150px', marginBottom: '10px' }} />
                </>
                <div className="ardmore" style={{ display: 'flex' }}>
                  <div className="row">
                
                    <div className="col-4 col-md-4 d-flex justify-content-center">
                    <div className="nose-palate-section" style={{ fontSize: '22px', display: 'flex', justifyContent: 'center' }}>
                      <div className="nose-container" style={{ borderRadius: '10px', width: '30%', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <b className="left-text">AROMAS IDENTIFIED</b>
                      </div>
                    </div>
                  </div>
                    <div className="col-8 col-md-8">
                      <div className="images-section" >
                      <div className="flavours">
                        <div className="circular-layout">
                          <div className="im-container" style={{ display: 'flex', alignItems: 'center' }}>
                            <div className="row-container" style={{ display: 'flex', flexDirection: 'column' }}>
                              <div className="im-container1">
                                <div className="image-grid" style={{ gridGap: '4px' }}>
                                  {getImages().map((aroma, index) => (
                                    <div className="small-image-container" key={index}>
                                      <img src={aroma.icon.preview_url} alt={`On the Palate Image ${index + 1}`} className="small-image" />
                                    </div>
                                  ))}
                                </div>
                                {apidata && apidata.data && apidata.data.length > 6 && (
                                <button className="smacid-btn v-all" style={{width:'28%' }} onClick={() => togglePopup(apidata.data, "AROMAS IDENTIFIED")}>
                                  View All
                                </button>
                              )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    </div>
                  </div>
                  
                  
                </div>
              </div>
            ) : (
              <div>
                <>
                  <h1 className="title1">THE PALATE / FLAVOUR</h1>
                  <img alt="palate" src={palate} style={{ width: '150px', marginBottom: '10px' }} />
                </>
                <div className="ardmore" >
                  {/* <div className="nose-palate-section" style={{ marginRight: '10px', fontSize: '22px' }}>
                    <div className="nose-container" style={{ borderRadius: '10px', marginBottom: '20px', width: '30%' }}>
                      <p className="left-text">FLAVOURS IDENTIFIED</p>
                    </div>
                  </div> */}
                <div className="row">
                  <div className="col-4 col-md-4 d-flex justify-content-center">
                    <div className="nose-palate-section" style={{ marginRight: '10px', fontSize: '22px', display: 'flex', justifyContent: 'center' }}>
                      <div className="nose-container" style={{ borderRadius: '10px', width: '30%', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <b className="left-text">FLAVOURS IDENTIFIED</b>
                      </div>
                    </div>
                  </div>
                  <div className="col-8 col-md-8">
                  <div className="images-section">
                    <div className="flavours">
                      <div className="circular-layout">
                        <div className="im-container" style={{ display: 'flex', alignItems: 'center' }}>
                          <div className="row-container" style={{ display: 'flex', flexDirection: 'column' }}>
                            <div className="im-container1">
                              <div className="image-grid" style={{ gridGap: '4px' }}>
                                {getImages().map((aroma, index) => (
                                  <div className="small-image-container" key={index}>
                                    <img src={aroma.icon.preview_url} alt={`On the Palate Image ${index + 1}`} className="small-image" />
                                  </div>
                                ))}
                              </div>
                              {apidata && apidata.data && apidata.data.length > 6 && (
                              <button className="smacid-btn v-all" style={{ width:'28%'}} onClick={() => togglePopup(apidata.data, "FLAVOURS IDENTIFIED")}>
                                View All
                              </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                </div>
                  
                  
                </div>
              </div>
            )}
          </div>

          {localStorage.getItem('isActive') === "0" ? (
            <h1 className="title1">RATE THE AROMATICS</h1>
          ) : (
            <p className="title1">RATE THE PALATE</p>
          )}
          <div className='d-flex justify-content-center'>
            {numbers.map((number) => (
              <div className="text-center" style={{ paddingRight: '5px' }} key={number}>
                <FontAwesomeIcon icon={faStar} className={`fas fa-star ${rating >= number ? 'myselected' : ''}`} style={{ fontSize: '22px' }} onClick={() => selectedRating(number)} />
                <br />
                <label>{number}</label>
              </div>
            ))}
          </div>
          <br />

          <div className='d-flex justify-content-center'>
            <button
              className="fixed-button"
              onClick={submitRating}
            >
              <span><b style={{ fontSize: '16px' }}>SUBMIT</b></span>
            </button>
          </div>
        </div>
      }

        <Modal
          isOpen={modalOpen}
          onRequestClose={closeModal}
          contentLabel="Confirmation Modal"
          style={{
            content: {
              height: '157px',
              margin: 'auto',
              width: '80%',
              textAlign: 'center'
            }
          }}
        >
          <div>
            <p>Please submit your feedback by clicking the submit button...</p>
            <button style={{ float: 'right', backgroundColor: '#0A253C' }} className="btn btn-success" onClick={() => navigate('/rating')}>Submit</button>
            <button style={{ float: 'left', backgroundColor: '#0A253C' }} className="btn btn-primary" onClick={handleBack}>Go back</button>
          </div>
        </Modal>

        <Modal
          isOpen={showPopup}
          onRequestClose={() => setShowPopup(false)}
          contentLabel="View All Popup"
          style={{
            content: {
              // height: 'fit-content',
              width: '80%',
              margin: 'auto',
              overflow: 'auto'
            }
          }}
        >
          {/* <h2>{popupTitle}</h2> */}
          <div className="popup-grid">
            {popupData.map((item, index) => (
              <div key={index} className="popup-item">
                <img src={item.icon.url} alt={item.name} className="popup-image" />
                <p style={{fontSize: '12px', fontWeight: 'bold'}}>{item.name}</p>
              </div>
            ))}
          </div>
          <button onClick={() => setShowPopup(false)} className="btn btn-primary fixed-icon" 
        >&times;
</button>

          {/* <button onClick={() => setShowPopup(false)} 
          className="btn btn-primary" 
          style={{ backgroundColor: '#0A253C'}}>Close</button> */}
        </Modal>
      </div>
    </>
  );
};

export default Rating;
