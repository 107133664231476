import React, { useState, useEffect } from "react";
import "./addWhiskyForm.css";
import uploadIcon from "../../assets/assest/Icon feather-upload.png";
import { useNavigate } from 'react-router-dom';
import Loader from "../loader/loader.js";
import Modal from 'react-modal';
import backbutton from '../../assets/assest/back.jpeg'
import ReCAPTCHA from 'react-google-recaptcha';
import imageCompression from "browser-image-compression";

const AddWhiskyForm = () => {

  const navigate = useNavigate();


  const [isLoading, setIsLoading] = useState(false);
   const [file, setFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState('Size Limit:10MB');
  const [apiData, setApiData] = useState(null);
  const [searchData, setSearchData] =useState(null);
  const [query, setQuery] = useState('');
  // const history = useHistory();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [captchaError, setCaptchaError] = useState(false);
  const onCaptchaChange = (value) => {
    setCaptchaValue(value);
    setCaptchaError(false);
  };

  const handleOpenModal = () => {
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      width: '90%',
      padding: '20px',
      border: 'none',
      position: 'absolute',
      borderRadius: '4px',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.2)',
      backgroundColor: '#fff',
      zIndex: 9999
    }
  };

  const [formData, setFormData] = useState({
    dropdown: '',
    country_name: '',
    file: null,
    abv:'',
    dropdown1:'',
    age:'',
    filteration:'',
    distillery_name:''
  });

  const [formErrors, setFormErrors] = useState({
    dropdown: '',
    dropdown1:'',
    country_name: '',
    file: null,
    abv:'',
    age:'',
    filteration:'',
    distillery_name:''
  });

  const checkfile=(file)=> {
    const formData = new FormData();
    formData.append('file', file);
    fetch('https://admin.smacflavourfolio.com/api/user/checkImages', {
      method: 'POST',
      body: formData
    })
    .then(response => response.json())
      .then(data => {
      // Handle the response
      if(data["errors"]==false)
        {
          setFile(file);
          const reader = new FileReader();
          reader.onloadend = () => {
          setPreviewImage(reader.result);
        };
        reader.readAsDataURL(file);
          setErrorMessage('');
        }
        else
        {
          setFile(null);
          setPreviewImage(null);
          setErrorMessage(data["message"]);
        }
    })
    .catch(error => {
      // Handle the error
    });
  }
  const handleChange = (event) => {
    const { name, value} = event.target;
      setFormData({ ...formData, [name]: value });
  };


  const handleImageUpload = async (imageFile) => {
    // Options for compression
    const options = {
      maxSizeMB: 2, // (2 MB)
      maxWidthOrHeight: 1024, // (You can set a maximum width/height)
      useWebWorker: true, // optional, defaults to true
    };

    try {
      const compressedImage = await imageCompression(imageFile, options);
      setFile(compressedImage);
      checkfile(compressedImage);
      
      // You can now handle the compressed image
      console.log("Original File Size:", imageFile.size / 1024, "KB");
      console.log("Compressed File Size:", compressedImage);

      // // Example: create URL for the compressed image for preview or upload
      // const compressedImageUrl = URL.createObjectURL(compressedImage);
      // console.log("Compressed Image URL:", compressedImageUrl);
    } catch (error) {
      console.error("Error during image compression:", error);
    }
  };

  function handleFileChange(event){
    const file = event.target.files[0];

    if (file) {
      const fileSizeInBytes = file.size;
      const maxSizeInBytes = 10 * 1024 * 1024; // 10MB

      if (fileSizeInBytes <= maxSizeInBytes) {
        const allowedExtensions = ['jpg', 'jpeg', 'png'];
        const fileExtension = getFileExtension(file.name);
        if (allowedExtensions.includes(fileExtension.toLowerCase())) 
          { 
            handleImageUpload(file);
          }
        else
          {
            setFile(null);
            setPreviewImage(null);
            setErrorMessage('Invalid file extension. Only JPG, JPEG, and PNG files are allowed.');
          }
      } else {
        setFile(null);
        setPreviewImage(null);
        setErrorMessage('File size exceeds the maximum limit (10MB).');
      }
    } else {
      setFile(null);
      setPreviewImage(null);
      setErrorMessage('Size Limit:10MB');
    }
  }

  // function handleFileChange(event) {
  //   const file = event.target.files[0];


  //   if (file) {
  //     const fileSizeInBytes = file.size;
  //     const maxSizeInBytes = 10 * 1024 * 1024; // 10MB

  //     if (fileSizeInBytes <= maxSizeInBytes) {
  //       const allowedExtensions = ['jpg', 'jpeg', 'png'];
  //       const fileExtension = getFileExtension(file.name);
  //       if (allowedExtensions.includes(fileExtension.toLowerCase())) 
  //         { 
  //           setFile(file);
  //           checkfile(file)
  //         const reader = new FileReader();
  //         reader.onloadend = () => {
  //         setPreviewImage(reader.result);
  //       };
  //       reader.readAsDataURL(file);
  //         setErrorMessage('');

  //         }
  //       else
  //         {
  //           setFile(null);
  //           setPreviewImage(null);
  //           setErrorMessage('Invalid file extension. Only JPG, JPEG, and PNG files are allowed.');
  //         }
  //     } else {
  //       setFile(null);
  //       setPreviewImage(null);
  //       setErrorMessage('File size exceeds the maximum limit (10MB).');
  //     }
  //   } else {
  //     setFile(null);
  //     setPreviewImage(null);
  //     setErrorMessage('Size Limit:10MB');
  //   }
  // }

  const getFileExtension = (fileName) => {
    const parts = fileName.split('.');
    return parts[parts.length - 1];
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if(captchaValue)
      {
    if (validateForm()) {
      setIsLoading(true);
      // Perform form submission
    const mformData = new FormData();
    mformData.append('whiskey_name', query);
    mformData.append('member_id', localStorage.getItem('smacid'));
    mformData.append('category', formData.dropdown);
    mformData.append('country', formData.country_name);
    mformData.append('colour', formData.dropdown1);
    mformData.append('abv', formData.abv);
    mformData.append('filteration', formData.filteration);
    mformData.append('age', formData.age);
    mformData.append('distillery_name', formData.distillery_name);
    mformData.append('file', file);
    fetch('https://admin.smacflavourfolio.com/api/user/whiskeys_add', {
      method: 'POST',
      body: mformData
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setIsLoading(false);
        if(data["errors"])
        {
         //  setRedirect(true);
          //  history.push('https://smacindia.com/membership');
          // window.location.href = 'https://smacindia.com/membership/';
         // window.open('https://smacindia.com/membership/');
          //  console.log('result',apiData["errors"]);
          setCaptchaError(false);
          setCaptchaValue(null);
        }
        else
        {
          handleOpenModal();
          setCaptchaError(false);
          setCaptchaValue(null);
          // localStorage.setItem('smacid',member_id);
          // localStorage.setItem('member_name',data["data"]["name"]);
          // console.log('result',apiData["errors"]);
        }
      });
    }
    else
    {
      setCaptchaError(true);
    }
    }
  };

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    // Validate name field
    if (!formData.filteration) {
      isValid = false;
      errors.filteration = 'Filteration is required';
    }

    //Validate email field
    if (formData.dropdown==null) {
      isValid = false;
      errors.dropdown = 'Category is required';
    }

    setFormErrors(errors);

    return isValid;
  };

  const handlePopstate = () => {
    window.history.back();
  };

  const handleInputChange = (event) => {
    const { value } = event.target;
    setQuery(value);
    if(value.length>0)
    {
      const a=localStorage.getItem('apiData');
    setApiData(JSON.parse(a));
    console.log('api data',apiData);
    if(apiData!=null)
     {
    const filteredItems = apiData["data"].filter((item) =>
    item["whiskey_name"].toLowerCase().includes(value.toLowerCase())
    );
    console.log('search data',filteredItems);
     setSearchData(filteredItems);
    }
    else
    {
      setSearchData(null);
    }
  }
    else
    {
     setSearchData(null); 
    }
  };


  const handleClick= (param,param2,param3) =>
  {
    localStorage.setItem('whiskey_id', param);
    localStorage.setItem('whiskey_name', param2);
    localStorage.setItem('whisky_image',param3);
    console.log(param3);
    localStorage.setItem('is_on_the_nose','0');
    localStorage.setItem('is_on_the_palate','0');
    localStorage.setItem('finish','0');
    localStorage.setItem('completed_nose',null);
    localStorage.setItem('completed_palate',null);
    localStorage.setItem('on_the_palate',null);
    localStorage.getItem('selectedSubcategories',null);
    localStorage.setItem('on_the_nose',null);
    navigate("/Mainpage");
  }

  useEffect(() => {
    // This code will execute when the component mounts or updates
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (<div className="container container-fluid bg">
        <div className="row">
        <form onSubmit={handleSubmit}>
          <div className="col-lg-12 col-md-6 col-sm-4 p-2">
            <div>
              <button
                type="button"
                className="btn-link"
                style={{ color: "black",border:'none',background:'transparent',cursor:'pointer' }}
                 onClick={()=>handlePopstate()}
                >
                <div className='gradient-border'>
                  <img className="gradient-border-img" src={backbutton} style={{cursor:'pointer',width:'50px',height:'50px',borderRadius:'13px'}}/>
                </div>
              </button>
            </div>
            <div className="container mb-5">
              <div style={{ paddingTop:'10px',paddingBottom:'10px'}}>
                <h2>Add Whisky</h2>
              </div>
              <div className="form-floating input-shadow form-floating-sm mb-3">
                <input
                  type="text"
                  className="form-control bg-color"
                  id="whisky_name"
                  placeholder="Whisky name"
                  name="whisky_name" 
                  value={query} 
                  onChange={handleInputChange} 
                  required
                />
                 {formErrors.whisky_name && <span>{formErrors.whisky_name}</span>}
                <label for="whisky_name" style={{ zIndex:'0'}}>Whisky Name*</label>          <div className="row">
            {searchData!=null?
            (searchData.map((data) => (
                 <div className="col-12" style={{ textAlign:'left',paddingLeft:'20px'}} onClick={() => handleClick(data["id"],data["whiskey_name"],data["icon"]["url"])}>
                       {/* <img className="whiskyBottle" src={data["icon"]["url"]} alt="" style={{ width:'60px' }}/> */}
                       <p className="truncate">
                         <b>{data["whiskey_name"]}</b>
                       </p>
                     </div>
            )))
            :<div></div>}
            </div>

              </div>
              <div className="form-floating input-shadow  mb-3">
              <select id="dropdown" 
              name="dropdown" 
              className="form-control bg-color"
              // value={formData.dropdown} 
              onChange={handleChange} required
              >
                <option selected disabled>Please select</option>
                <option style={{backgroundColor:'#F4F6FC'}} value="0">Single Malt</option>
                <option style={{backgroundColor:'#F4F6FC'}} value="1">Blended Malt</option>
                <option style={{backgroundColor:'#F4F6FC'}} value="2">Single Grain</option>
                <option style={{backgroundColor:'#F4F6FC'}} value="3">Blended Grain</option>
                <option style={{backgroundColor:'#F4F6FC'}} value="4">Blended</option>
              </select>
              {formErrors.dropdown && <span style={{color:'red',fontSize:'12px'}}>{formErrors.dropdown}</span>}
                <label for="dropdown" style={{ zIndex:'0'}}>Category*</label>
              </div>
              {/* <div className="form-floating input-shadow mb-3">
                <textarea
                  className="form-control"
                  placeholder="Leave a comment here"
                  id="tasting_notes"
                  style={{ height: "100px" }}
                  name="tasting_notes" 
                  value={formData.tasting_notes} 
                  onChange={handleChange}
                ></textarea>
                <label for="tasting_notes">Tasting Notes</label>
              </div> */}
              <div className="form-floating input-shadow mb-3">
                <input
                  type="text"
                  className="form-control bg-color"
                  id="abv"
                  placeholder="ABV*"
                  name="abv" 
                 required
                  value={formData.abv} 
                  onChange={handleChange}
                />
                <label for="abv" style={{ zIndex:'0'}}>ABV*</label>
              </div>
              <div className="form-floating input-shadow mb-3">
                <input
                  type="text"
                  className="form-control bg-color"
                  id="distillery_name" 
                  name="distillery_name" 
                  value={formData.distillery_name}
                  placeholder="Distillery Name*" 
                  required
                  onChange={handleChange}
                />
                
                <label for="floatingPassword" style={{ zIndex:'0'}}>Distillery Name*</label>
              </div>
              <div className="form-floating input-shadow mb-3">
              <input
                type="text"
                className="form-control bg-color"
                id="series" 
                name="series" 
                value={formData.series || ""}  
                placeholder="Series if Any" 
                onChange={handleChange}
              />
              <label htmlFor="series" style={{ zIndex: '0' }}>Series if Any</label>
            </div>

             
              <div className="form-floating input-shadow mb-3">
              <input
                type="date"
                className="form-control bg-color"
                id="bottled_date" 
                name="bottled_date" 
                value={formData.bottled_date || ""}  
                placeholder="Bottled Date" 
                onChange={handleChange}
              />
              <label htmlFor="bottled_date" style={{ zIndex: '0' }}>Bottled Date</label>
            </div>
            <div className="form-floating input-shadow mb-3">
            <input
              type="date"
              className="form-control bg-color"
              id="date_distilled" 
              name="date_distilled" 
              value={formData.date_distilled || ""}  
              placeholder="Date Distilled" 
              onChange={handleChange}
            />
            <label htmlFor="date_distilled" style={{ zIndex: '0' }}>Date Distilled</label>
          </div>

              <div className="form-floating input-shadow mb-3">
              <select id="dropdown1" 
              className="form-control bg-color"
              name="dropdown1" 
              value={formData.dropdown1} 
              onChange={handleChange}
              >
                <option disabled selected>Please select</option>
                <option style={{backgroundColor:'#F4F6FC'}} value="0">Distillery Bottling</option>
                <option style={{backgroundColor:'#F4F6FC'}} value="1">Independent Bottling</option>
                {/* <option style={{backgroundColor:'#F4F6FC'}} value="2">Information Not</option> */}
              </select>
                <label for="dropdown1" style={{ zIndex:'0'}}>Bottling Type</label>
              </div>
              <div className="form-floating input-shadow  mb-3">
              <select id="filteration"
              name="filteration" 
              className="form-control bg-color"
              // value={formData.dropdown} 
              onChange={handleChange}
              //  required
              >
                <option style={{backgroundColor:'#F4F6FC'}} selected disabled>Please select</option>
                <option style={{backgroundColor:'#F4F6FC'}} value="0">Chill filtered</option>
                <option style={{backgroundColor:'#F4F6FC'}} value="1">Non - chill filtered</option>
                {/* <option style={{backgroundColor:'#F4F6FC'}} value="2">Not specified</option> */}
              </select>
              {formErrors.filteration && <span style={{color:'red',fontSize:'12px'}}>{formErrors.filteration}</span>}
                <label for="filteration" style={{ zIndex:'0'}}>Filteration</label>
              </div>
              <div className="form-floating input-shadow mb-3">
              <select id="dropdown1" 
              className="form-control bg-color"
              name="dropdown1" 
              // value={formData.dropdown1} 
              onChange={handleChange}
              >
                <option disabled selected>Please select</option>
                <option style={{backgroundColor:'#F4F6FC'}} value="0">Artificial Colour Added</option>
                <option style={{backgroundColor:'#F4F6FC'}} value="1">No Artificial Colour Added</option>
                <option style={{backgroundColor:'#F4F6FC'}} value="2">Information Not</option>
              </select>
                <label for="dropdown1" style={{ zIndex:'0'}}>Color</label>
              </div>
             
              <div className="form-floating input-shadow mb-3">
              <input
                type="number"
                className="form-control bg-color"
                id="num_bottles_released" 
                name="num_bottles_released" 
                value={formData.num_bottles_released || ""}  
                placeholder="Number of Bottles Released" 
                onChange={handleChange}
              />
              <label htmlFor="num_bottles_released" style={{ zIndex: '0' }}>Number of Bottles Released</label>
              </div>

              {/* <div className="form-floating input-shadow mb-3">
                <input
                  type="text"
                  className="form-control bg-color"
                  placeholder="Country of Origin*"
                  id="country_name" 
                  name="country_name" 
                  value={formData.country_name} 
                  onChange={handleChange}
                  required
                />
                
                <label for="country_name" style={{ zIndex:'0'}}>Country of Origin*</label>
              </div> */}
              <div className="input-shadow mb-3">
              <label for="file" style={{ zIndex:'0'}} className="custom-file-upload form-control bg-color">
                              Upload Image
                              {previewImage==null ? (<img src={uploadIcon} style={{height:'20px',float:'right',Color:'#0A253C'}} />):(<div style={{float:'right',color:'green'}}>change</div>)}</label>
                <input id="file" type="file"
                className="bg-color"
                name="file" 
                onChange={handleFileChange}
                />
                  
              </div>
              <p style={{color:'red'}}>{errorMessage}</p>
              {previewImage!=null ? (
                <img src={previewImage} alt="Preview" height={"300"} width={"100%"} style={{marginBottom:'20px'}}/>
      
              ):(<div></div>)}
              <div className="Captcha">
              <div>
               <ReCAPTCHA
                  sitekey="6LeHw9spAAAAAEeAEVgYOhY8TH6m9CK2E_t1aj64"
                  onChange={onCaptchaChange}
                />
               {captchaError && (
                 <div style={{ color: 'red', marginTop: '5px' }}>
                  Please complete the CAPTCHA
                </div>
               )}
            </div>
            </div>
              <div className="addcheck">
                <button type="submit" className="add btn-shadow" style={{ height:'40px',backgroundColor:'#0A253C',border:'2px solid #0A253C',marginTop:'10px', marginBottom: '10px'}} >
                  ADD
                </button>
              </div>
            </div>
          </div>
         </form>
        </div>
      </div>

     )}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={handleCloseModal}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <p>Thank you for your contribution. Please allow us to validate the inputs before it
              is made available on Flavour Folio</p>
        <p>Wait for admin verification...</p>
        <button className="btn btn-success" onClick={()=>{navigate('/choosewhisky')}}  style={{backgroundColor:'#0A253C'}}>ok</button>
      </Modal>

    </>
  );
};

export default AddWhiskyForm;
