import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Logo from "./pages/Landingpage/Logo";
import Dashboard from "./pages//DashBoard/Dashboard";
import ProfileDashboard from "./pages/profile/dashboard";
import Smacid from "./pages/SmacId/Smacid";
import Mainpage from "./pages/Mainpage/Mainpage";
import AddWhiskyForm from "./pages/AddWhiskyForm/AddWhiskyForm";
import Choosewhisky from "./pages/ChooseWhisky/ChooseWhisky";
import Finishonthewhisky from "./pages/Finishonthewhisky/Finishonthewhisky";
import Onthepalate from "./pages/Onthepalate";
import ChooseFile from './pages/ChooseFile/ChooseFile';
import OnTheNose from './pages/OnTheNose/OnTheNose';
import Search from './pages/OnTheNose/Search';
import Rating from './pages/Rating';
import SearchingWhiskey from './pages/ChooseWhisky/Search';
import Submit from './pages/Submit';
import ThanksPage from "./pages/thankspage";
import Profilepage from "./pages/Profilepage/Profilepage";
import ProfileDetails from "./pages/profile_details/profile_details";
import EditProfile from "./pages/editprofile/editprofile";
import FlavourFolio from "./pages/flavourfolio/flavourfolio";
import FlavourFolioRating from "./pages/flavourfoliorating/flavourfoliorating";
import FlavourFolioClub from "./pages/flavourfolioclub/flavourfolioclub";
import Club from "./pages/club/club";
import Club1 from "./pages/club1/club1";
import Club2 from "./pages/club2/club2"
import DashBoardn from "./pages/dashboardn/dashboardn";
import { AuthProvider } from './pages/Services/AuthContext';
import PrivateRoute from "./PrivateRoute";
import PrivateRoute1 from "./PrivateRoute1";
import WhiskyClub from "./pages/whiskyclub/whiskyclub";
import DesignPage from "./pages/whiskyclub/displayDesign";
import Whisky from "./pages/whiskey/whisky";
import LoyalityPoints from "./pages/loyaltypoints/loyalitypoints";
import OverallReview from './pages/Overallreview/overallreview'

function App() {
  useEffect(() => {
    function handleResize() {
          const root = document.getElementById('root');
          if (window.innerWidth > 1024) { // Assuming desktop screens are wider than 1024px
              root.style.width = '375px'; // Force mobile screen width
              root.style.margin = '0 auto'; // Center the content on larger screens
          } else {
            console.log("dd");
              root.style.width = '100%'; // Full width for smaller screens
          }
      }

      handleResize(); // Set initial size
      window.addEventListener('resize', handleResize); // Listen for window resize

      return () => window.removeEventListener('resize', handleResize); // Cleanup
  }, []);
  return (
   <AuthProvider>
    <BrowserRouter>
      <Routes>
        {/* <Route path="/" element={<PrivateRoute1 element={<Logo />}/>} /> */}
        <Route path="/dashboard" element={<PrivateRoute1 element={<Dashboard />}/>} />
        <Route path="/smacid" element={<PrivateRoute1 element={<Smacid />}/>} />
        <Route path="/mainpage" element={<PrivateRoute element={<Mainpage/>}/>}/>
        <Route path="/addWhiskyForm" element={<PrivateRoute element={<AddWhiskyForm />}/>} />
        <Route path="/choosewhisky" element={<PrivateRoute element={<Choosewhisky />}/>} />
        <Route path="/finishonthewhisky" element={<PrivateRoute element={<Finishonthewhisky />}/>} />
        <Route path="/ChooseFile" element={<PrivateRoute element={<ChooseFile />}/>} />
        <Route path="/OnTheNose" element={<PrivateRoute element={<OnTheNose />}/>} />
        <Route path="/OnTheNosePlate" element={<PrivateRoute element={<Onthepalate />}/>} />
        <Route path="/rating" element={<PrivateRoute element={<Rating />}/>} />
        <Route path="/submit" element={<PrivateRoute element={<Submit />}/>} />
        <Route path="/thanksPage" element={<PrivateRoute element={<ThanksPage />}/>} />
        <Route path="/SearchingWhiskey" element={<PrivateRoute element={<SearchingWhiskey />}/>} />
        <Route path="/Search" element={<PrivateRoute element={<Search />}/>} />
        <Route path="/profilepage" element={<PrivateRoute element={<Profilepage/>}/>}/>
        <Route path="/profile_details" element={<PrivateRoute element={<ProfileDetails/>}/>}/>
        <Route path="/editprofile" element={<PrivateRoute element={<EditProfile/>}/>}/>
        <Route path="/flavourfolio" element={<PrivateRoute element={<FlavourFolio/>}/>}/>
        <Route path="/flavourfoliorating" element={<PrivateRoute element={<FlavourFolioRating/>}/>}/>
        <Route path="/flavourfolioclub" element={<PrivateRoute element={<FlavourFolioClub/>}/>}/>
        <Route path="/club" element={<PrivateRoute element={<Club/>}/>}/>
        <Route path="/club1" element={<PrivateRoute element={<Club1/>}/>}/>
        <Route path="/club2" element={<PrivateRoute element={<Club2/>}/>}/>
        <Route path="/profiledashboard" element={<PrivateRoute element={<DashBoardn/>}/>}/>
        <Route path="/whiskyclub" element={<PrivateRoute element={<WhiskyClub/>}/>}/>
        <Route path="/whisky" element={<PrivateRoute element={<Whisky/>}/>}/>
        <Route path="/loyalitypoints" element={<PrivateRoute element={<LoyalityPoints/>}/>}/>
        <Route path="/overallreview" element={<PrivateRoute element={<OverallReview/>}/>}/>
        <Route path="/design" element={<DesignPage />} />
        <Route path="/*" element={<Logo />} />
      </Routes>
    </BrowserRouter>
    </AuthProvider>
  );
}
export default App;
