import React, { useState, useEffect } from "react";
import "./mainpage.css";
import '../rating.css';
import { Link } from 'react-router-dom';
import { useNavigate} from 'react-router-dom';
import whisky from "../../assets/assest/Background.png";
import bottle from "../../assets/assest/default-png.png";
import home from '../../assets/assest/home.png'
import nose from '../../assets/assest/nose.png'
import palate from '../../assets/assest/palate.png'
import finish from '../../assets/assest/finish.png'
import feedback from '../../assets/assest/feedback.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faStar } from '@fortawesome/free-solid-svg-icons';
import Loader from "../loader/loader.js";
import backbutton from '../../assets/assest/back.jpeg'
import { useLocation} from 'react-router-dom';
import Modal from 'react-modal';
import Slider from 'react-slick';

const Mainpage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const location = useLocation();
  const [isClick,setClick] =useState(0);
  const { report_id} = location.state || {};

  const customStyles = {
    content: {
      backgroundColor:'#f9f9f9',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      width: '90%',
      padding: '20px',
      border: 'none',
      borderRadius: '4px',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.2)',
      // backgroundColor: '#fff'
    }
  };

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};
const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

useEffect(() => {
  // This code will execute when the component mounts or updates
  window.scrollTo(0, 0); // Scroll to the top of the page
}, []);

const handleClick = (param) => {
  localStorage.setItem('type', param);
  localStorage.setItem('isActive', param);
  navigate("/OnTheNose");
  console.log(param);
};

const handleClick1 = (param) => {
  localStorage.setItem('type', '1');
  localStorage.setItem('isActive', '1');
  navigate("/finishonthewhisky");
  console.log(param);
};

const handleOpenModal = (val) => {
  setClick(val);
  setModalIsOpen(true);
};

const handleCloseModal = () => {
  setModalIsOpen(false);
};

const handleClick2 = (param) => {
  localStorage.setItem('type', param);
  localStorage.setItem('isActive', param);
  navigate("/submit");
  console.log(param);
};
const handlePopstate = () => {
  if(isClick==1)
  {
    localStorage.removeItem('whiskey_id');
    localStorage.removeItem('on_the_nose');
    localStorage.removeItem('on_the_palate');
    localStorage.removeItem('on_the_nose_rating');
    localStorage.removeItem('on_the_palate_ratings');
    localStorage.removeItem('ratings');
    localStorage.removeItem('review');
    localStorage.removeItem('rating');
    localStorage.removeItem('reviee');
    window.history.back();
    // navigate("/choosewhisky");
  }
  if(isClick==2)
  {
    localStorage.removeItem('whiskey_id');
    localStorage.removeItem('on_the_nose');
    localStorage.removeItem('on_the_palate');
    localStorage.removeItem('on_the_nose_rating');
    localStorage.removeItem('on_the_palate_ratings');
    localStorage.removeItem('ratings');
    localStorage.removeItem('review');
    localStorage.removeItem('rating');
    localStorage.removeItem('reviee');
    navigate("/profileDashboard");
  }
};

  const isOnTheNoseCompleted = localStorage.getItem('is_on_the_nose') === '1';
  const isOnThePalateCompleted = localStorage.getItem('is_on_the_palate') === '1';
  const isFinishCompleted = localStorage.getItem('finish') === '1';
  const isFeedbackCompleted = localStorage.getItem('feedback') === '1';
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (<div className="" style={{ overflowX:'hidden' }}>
     <div style={{ position: 'relative' }}>
  <img 
    src={localStorage.getItem('whisky_image')} 
    alt="Image 1" 
    style={{
      height: '250px',
      width: '100%',
      borderBottomLeftRadius: '30px',
      borderBottomRightRadius: '30px'
    }}
  />
  <div className="p-2" style={{ position: 'absolute', top: '0', left: '0', width: '100%' }}>
    <div style={{ float: 'left'}}>
      <button
        type="button"
        className="btn-link"
        style={{
          color: "black",
          border: 'none',
          background: 'transparent',
          cursor: 'pointer',
        }}
        onClick={() => handleOpenModal(1)}
      >
        <div className='gradient-border'>
        <img className="gradient-border-img" src={backbutton} style={{ cursor: 'pointer', width: '50px', height: '50px', borderRadius: '13px' }} />
        </div>
      </button>
    </div>

    <div style={{ float: 'right'}}>
      <button
        type="button"
        className="btn-link"
        style={{ color: "black", border: 'none', background: 'transparent', cursor: 'pointer' }}
        onClick={() => handleOpenModal(2)}
      >
        <div className='gradient-border'>
        <img className="gradient-border-img" src={home} style={{ cursor: 'pointer', width: '50px', height: '50px', borderRadius: '13px' }} />
        </div>
      </button>
    </div>
  </div>
</div>
        <div  className="container mt-4">
          <h5 className="d-flex justify-content-center align-items-center">
            <strong>  {localStorage.getItem('whiskey_name')}</strong>
           {/* <strong>WELCOME {localStorage.getItem('member_name')}</strong> */}
          </h5>
          <h6 className="d-flex justify-content-center align-items-center text-center">
            {/* SUBMIT YOUR REVIEW FOR {localStorage.getItem('whiskey_name')} */}
          </h6>
        </div>
        <br/>
        <div className="container card-menu mb-5">
          <div className="row">
            <div className="col-6 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center  align-items-center card-menu-item card-menu-item-line"
             onClick={() => handleClick('0')} style={{cursor:'pointer'}}>
            {/* <Link to="/OnTheNose" style={{textDecoration:'none'}}> */}
            <h4 style={{textAlign:'center'}}>
                <div className='gradient-border'>
                  <img className="menu-img gradient-border-img mb-2" alt="" src={nose}/>
                </div>
                <span style={{fontSize:'14px',color:'black',textDecoration:'none'}}><b> AROMATICS </b></span>
                <div className={`${localStorage.getItem('is_on_the_nose')=='1' ? 'visibleClass' : 'notVisibleClass'}`}>
                <div className={`d-flex  justify-content-center  align-items-center`}>
                <div className={`mbox1`} style={{float:'right'}}>
                  <i class="fa-solid fa-check"></i>
                </div>&nbsp;
                <span style={{color:'#327D01',fontSize:'12px',paddingTop:'3px'}}>Completed</span>
                </div>
                <div className="d-flex" style={{textAlign:'center',justifyContent:'center'}}>
                {numbers.map((number) => (
                <div class="text-center" style={{paddingRight:'0px'}}>
                    <FontAwesomeIcon icon={faStar} className={`fas fa-star ${localStorage.getItem('on_the_nose_rating')>=number ? 'myselected1' : ''}`} style={{fontSize:'10px'}}/>
                </div>
               ))}
               </div>
                </div>
              </h4>
              {/* </Link> */}
            </div>
            
            
          <div className="col-6 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center  align-items-center card-menu-item" 
             onClick={() => handleClick('1')} style={{cursor:'pointer',marginTop: isOnTheNoseCompleted && !isOnThePalateCompleted ? '-56px' : '0'}}>
            {/* <Link to="/OnTheNose" style={{textDecoration:'none',color:'black',}}> */}
            <h4 style={{textAlign:'center'}}>
            <div className='gradient-border'>
              <img className="menu-img gradient-border-img mb-2" src={palate} alt=""/>
              </div>
              <br/>
                <span style={{fontSize:'14px',color:'black'}}><b>ON THE PALATE</b></span>
                <div className={`${localStorage.getItem('is_on_the_palate')=='1' ? 'visibleClass' : 'notVisibleClass'}`}>
                <div className={`d-flex  justify-content-center  align-items-center`}>
                <div className={`mbox1`} style={{float:'right'}}>
                  <i class="fa-solid fa-check"></i>
                </div>&nbsp;
                <span style={{color:'#327D01',fontSize:'12px',paddingTop:'3px'}}>Completed</span>
                </div>
                <div className="d-flex">
                {numbers.map((number) => (
                <div class="text-center" style={{paddingRight:'0px'}}>
                    <FontAwesomeIcon icon={faStar} className={`fas fa-star ${localStorage.getItem('on_the_palate_ratings')>=number ? 'myselected1' : ''}`} style={{fontSize:'10px'}}/>
                </div>
               ))}
               </div>
                </div>
              </h4>
              {/* </Link> */}
          </div>
          </div>
          <div className="row mt-3">
          <div 
          className="col-6 col-lg-6 col-md-6 col-sm-12 d-flex flex-column justify-content-center align-items-center card-menu-item card-menu-item-line" 
          onClick={() => handleClick1('2')}
          style={{cursor: 'pointer'}}
        >
          <div className='gradient-border'>
            <img 
              className="menu-img gradient-border-img mb-2" 
              style={{textAlign: 'center'}} 
              src={finish} 
              alt="Finish"
            />
          </div>
          <h4 style={{textAlign: 'center'}}>
            <span style={{fontSize: '14px', color: 'black'}}><b>FINISH</b></span>
            <div className={`${localStorage.getItem('finish') === '1' ? 'visibleClass' : 'notVisibleClass'}`}>
              <div className="d-flex justify-content-center align-items-center">
                <div className="mbox1" style={{float: 'right'}}>
                  <i className="fa-solid fa-check"></i>
                </div>&nbsp;
                <span style={{color: '#327D01', fontSize: '12px', paddingTop: '3px'}}>Completed</span>
              </div>
            </div>
          </h4>
        </div>

                  
            
          <div className="col-6 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center align-items-center card-menu-item card-menu-item-line" 
            onClick={()=>handleClick2('3')}
            style={{cursor:'pointer',marginTop: isFinishCompleted && !isFeedbackCompleted? '-20px' : '8px' }}
            >
            {/* <Link to="/submit" style={{textDecoration:'none',color:'black'}}> */}
              <h4 style={{textAlign:'center'}}>
             
              <div className='gradient-border '>
                <img className="menu-img gradient-border-img mb-2" src={feedback} alt=""/>
              </div>
                  
                <p style={{fontSize:'14px',color:'black',overflow: 'hidden',textOverflow: 'ellipsis', paddingTop:'5px'}}><b>OVERALL RATING</b></p>
              </h4>
              {/* </Link> */}
            </div>
          
          </div>
        </div>
        <Modal
        isOpen={modalIsOpen}
        onRequestClose={handleCloseModal}
        contentLabel="Example Modal"
        style={customStyles}
      >
       <p>Go back! you will lost your changes.</p>
        <button style={{float:'right',backgroundColor:'#0A253C'}} className="btn btn-success" onClick={()=>handlePopstate()}>OK</button>
        <button style={{float:'left',backgroundColor:'#0A253C'}} className="btn btn-primary" onClick={()=>handleCloseModal()} >CLOSE</button>
      </Modal>
      </div>
      )}
    </>
  );
};

export default Mainpage;
