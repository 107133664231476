import React, { useEffect, useState } from "react";
import nose from "../../assets/assest/nose.png";
import palate from "../../assets/assest/palate.png";
import finish from "../../assets/assest/finish.png"
import logo from '../../assets/assest/default.png';
import logo1 from "../../assets/assest/logo.png";
import "./whisky.css";
import background1 from "../../assets/assest/bg.png";
import DescriptionComponent from "../description.js";
import background2 from "../../assets/assest/Theme1.png"
import background3 from "../../assets/assest/Theme2.png"
import note from "../../assets/assest/notes.png"
import { useLocation,useNavigate } from 'react-router-dom';
import Loader from "../loader/loader.js";
import { useAuth } from '../Services/AuthContext';
import newmale from '../../assets/assest/member_level_frames/newbie.png'
import newfemale from '../../assets/assest/member_level_frames/newbie_female.png';
import moderatorMale from '../../assets/assest/member_level_frames/moderator_male.png';
import moderatorFemale from '../../assets/assest/member_level_frames/moderator_female.png';
import expertMale from '../../assets/assest/member_level_frames/expert_male.png';
import expertFemale from '../../assets/assest/member_level_frames/expert_female.png';
import connoisseurMale from '../../assets/assest/member_level_frames/connoisseur_male.png';
import connoisseurFemale from '../../assets/assest/member_level_frames/connoisseur_female.png';
import enthusiastMale from '../../assets/assest/member_level_frames/enthusiast_male.png';
import enthusiastFemale from '../../assets/assest/member_level_frames/enthusiast_female.png';
import Modal from 'react-modal';
const Whisky = () => {
  const {logout,fetchProfile,profileData} =useAuth();
  const [apidata,setApiData]= useState(null);
  const [isLoading, setIsLoading]= useState(true);
  const location = useLocation();
  const { uploadedImage } = location.state || {};
  const navigate =useNavigate();
  const [showFullDescription, setShowFullDescription] = useState(false);
  const { report_id } = location.state || {};
  // const [bgColorIndex, setBgColorIndex] = useState(0);  
  // const bgColors = ['rgb(250 200 205 / 54%)', 'rgb(219 228 245 / 69%)', '#ffcc8057'];
  // const [showColorOptions, setShowColorOptions] = useState(false);
  const [bgImageIndex, setBgImageIndex] = useState(0);
  const bgImages = [background1,background2,background3];
  const [showImageOptions, setShowImageOptions] = useState(false);
  const [useProfile, setUseProfile] = useState(false); 
  const [useReview, setUseReview] = useState(''); 
  const [errorMessage, setErrorMessage] = useState('');
  const [whiskey_id] =useState()
  const [showNote, setShowNote] = useState(false);
  const [showProfileNote, setShowProfileNote] = useState(false);
  const [showReviewNote, setShowReviewNote] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState([]);
  const [paragraphs, setPara] = useState('');
  // const [popupTitle, setPopupTitle] = useState('');
  const togglePopup = (data, title) => {
    setPopupData(data);
    // setPopupTitle(title);
    setShowPopup(!showPopup);
    
  }
  const handleProfileNote = (event) => {
    event.preventDefault(); 
    event.stopPropagation();
    setShowProfileNote(!showProfileNote); 
  };

  const handleReviewNote = (event) => {
    event.preventDefault(); 
    event.stopPropagation();
    setShowReviewNote(!showReviewNote);
    // setShowProfileNote(false);
  };

  // const [uploadedImage, setUploadedImage] = useState(null);
  const handleProfileCheckboxChange = () => {
    if (!apidata || !apidata.data || !apidata.data.report.user.profile || !apidata.data.report.user.profile.url) {
      setErrorMessage("  Profile picture not set");
      return;
    }
    setErrorMessage('');
    setUseProfile(!useProfile);
  };
  
  const handleReview = () => {
    setUseReview(!useReview);
  };
  
  const hasOnTheNoseRating = apidata && apidata.data && apidata.data.report.on_the_nose_rating !== "null";
  const hasOnThePalateRating = apidata && apidata.data && apidata.data.report.on_the_palate_ratings !== "null";
  const hasFinishOption = apidata && apidata.data && apidata.data.report.finish_option !== "null";

  const hasAnyRating = hasOnTheNoseRating || hasOnThePalateRating || hasFinishOption;
  useEffect(() => {
    window.scrollTo(0, 0); 
    setIsLoading(true);
    async function fetchData()
    {
      try {
        const formData = new FormData();
        formData.append('report_id', report_id);
        console.log("report_id",report_id);
        const response = await fetch('https://admin.smacflavourfolio.com/api/user/reportDetail',{
        method:"POST",
        body:formData,
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
      });
        const data = await response.json();
        if(data!=null)
        {
          if (useReview) {
            console.log("Review:", data.data.review);
          }
        console.log("whisky_data ",data);
        setApiData(data);
        setIsLoading(false);
        const whiskeyId = data.data.report.whiskey_id;
        localStorage.setItem('whiskey_id', whiskeyId);
        console.log('whisky_id', whiskeyId);
        }
      } catch (error) {
        setIsLoading(false);
        console.error(error);
      }
    }
    fetchData();
}, []);

const goToHomepage = () =>{
  navigate("/profileDashboard");
}
const convertRatingToStar = (rating) => {
  let stars = '';
  for (let i = 0; i < rating; i++) {
    stars += '★';
  }
  return stars;
};
const convertRatingToStars = (rating) => {
  const totalStars = 10;
  const filledStars = Math.round(rating);
  const starsArray = Array.from({ length: totalStars }, (v, i) => i < filledStars ? '★' : '☆');
  return starsArray.join('');
};

// const fullDescription = apidata ?(apidata.data.report.description) : '';
//       const continuationText = "Read more";
//       const ReadLess = "Read Less"
//       const words = fullDescription.split(' ');
//       let maxWords = 30;
//       let visibleWords = words.slice(0, maxWords).join(' ');
    
//       while (visibleWords.length + continuationText.length > 150 && maxWords > 0) {
//         maxWords -= 1;
//         visibleWords = words.slice(0, maxWords).join(' ');
//       }
    
     
//       const toggleDescription = () => {
//         setShowFullDescription(!showFullDescription);
//       };
      
      
      const useDesignPage=()=>{
        const profileFrameUrl = getProfileImage(profileData.gender, getNextLevel(profileData.totalPointsEarned));
        console.log("bg",bgImageIndex);
        navigate('/whiskyclub', { 
          state: {
             report_id: report_id, 
            //  selectedColor: bgColors[bgColorIndex],
             selectedImage: bgImageIndex,
             useProfile:useProfile, 
             uploadedImage: uploadedImage,
             useReview:useReview,
             profileFrameUrl: profileFrameUrl } });
      };
      
    
      const toggleColorOptions = () => {
        setShowImageOptions(!showImageOptions);
        setBgImageIndex((prevIndex) => (prevIndex + 1) % bgImages.length);
      }
      
      
      const getNextLevel = (totalPointsEarned) => {
        if (totalPointsEarned >= 500) {
            return "Moderator";
        } else if (totalPointsEarned >= 301) {
            return "Expert";
        } else if (totalPointsEarned >= 151) {
            return "Connoisseur";
        } else if (totalPointsEarned >= 51) {
            return "Enthusiast";
        } else {
            return "Beginner";
        }
    };
    const getProfileImage = (gender, level) => {
      if (level === "Moderator") {
        return gender === "male" ? moderatorMale : moderatorFemale;
      } else if (level === "Expert") {
        return gender === "male" ? expertMale : expertFemale;
      } else if (level === "Connoisseur") {
        return gender === "male" ? connoisseurMale : connoisseurFemale;
      } else if (level === "Enthusiast") {
        return gender === "male" ? enthusiastMale : enthusiastFemale;
      } else {
        return gender === "male" ? newmale : newfemale;
      }
    };
    
      useEffect(()=>{
        setIsLoading(true);
        async function fetchData()
        {
          await fetchProfile();
          // setIsLoading(false);
          console.log("profile data is",profileData);
        }
        fetchData();
      },[location, fetchProfile]);
      // console.log(apidata.data.report.user.profile.url);
      const closeModal = () => {
        setShowProfileNote(false);
      };
      const closeReviewModal = () => {
        setShowReviewNote(false);
      };
      const hasOnTheNoseFlavours = apidata && apidata.data.onthenose.length > 0;
      const hasOnThePalateFlavours = apidata && apidata.data.onthepalate.length > 0;
return (
 <div>
  {isLoading ? (
    <Loader />
  ) :
  
  <div className="whisky-page-bg container-fluid" style={{
    width: '100%',
    minHeight: '100vh',
    backgroundImage: `url(${bgImages[bgImageIndex]})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  }}>
  
   

    
{/* <img src={bgImages[bgImageIndex]} alt="Background" className="background" /> */}
 <div className="smac" style={{marginTop:'20px',marginBottom:'20px'}}>
 <div style={{ position: 'relative', width: '50%',zIndex:'0' }}>
  {/* Conditionally render the logo */}
  {!useProfile || !(apidata && apidata.data && apidata.data.report.user.profile && apidata.data.report.user.profile.url) ? (
    <img src={logo1}  alt="logo" className="profile" />
  ) : (
    <div className="profile-frame">
      {/* Display profile image */}
      {profileData && (
        <img
          src={getProfileImage(profileData.gender, getNextLevel(profileData.totalPointsEarned))}
          className="profile-frame-img"
          alt="profile-img"
        />
      )}

      {/* Fallback to default profile image */}
      {useProfile && apidata && apidata.data && apidata.data.report.user.profile && apidata.data.report.user.profile.url && (
        <img
          src={apidata.data.report.user.profile.url}
          className="profile"
          alt="profile-frame"
        />
      )}
    </div>
  )}
</div>


  

<div className="ardmore" style={{ height: '130px', width: '50%' }}>
  <div className="ardmore-section d-flex align-items-center justify-content-center">
    {uploadedImage ? (
      <img src={uploadedImage} className="img-review img-thumbnail" alt="Uploaded" />
    ) : (
      apidata && apidata.data.report.whisky && apidata.data.report.whisky.icon && (
        <img src={apidata.data.report.whisky.icon.url} className="img-review img-thumbnail" alt="Whisky Icon" />
      )
    )}
  </div>
</div>

</div>
<div className="ardmore">
  <div className="nose-palate-section">
    <div className="row">
      <div className="col-4 col-md-4 d-flex justify-content-center align-items-center">
          <div className="nose-container text-center">
              <img src={nose} alt="Nose Image" className="large-images mx-auto d-block" 
                  style={{ width: '80px', height: '80px', borderRadius: '56px' }}
              />
              <p className="text-center">Aromatics</p>
          </div>
      </div>

      <div className="col-8 col-md-8">
      <div className="flavours">
      <div className="circular-layout">
        <div className="im-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div className="row-container">
            <div className="im-container1" style={{textAlign:'center'}}>
            {hasOnTheNoseFlavours ? (
                <div className="image-grid" style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gridGap: '4px' }}>
                  {apidata.data.onthenose.slice(0, 6).map((aroma, index) => (
                    <div className="small-image-container" key={index}>
                      <img src={aroma.icon.preview_url} alt={`On the Nose Image ${index + 1}`} className="small-images" />
                    </div>
                  ))}
                  {apidata.data.onthenose.length < 6 && apidata.data.randomonthenose.slice(0, Math.max(0, 6 - apidata.data.onthenose.length)).map((aroma, index) => (
                    <div className="small-image-container" key={index + apidata.data.onthenose.length}>
                      <img src={aroma.icon.preview_url} alt={`On the Nose Image ${index + apidata.data.onthenose.length + 1}`} className="small-images" />
                    </div>
                  ))}
                </div>
                ) : (
                  <p className="text-center ">No flavours selected</p>
              )}
               {apidata && apidata.data.onthenose.length > 1 && (
              <button className="smacid-btn v-all" 
              onClick={() => togglePopup([...apidata.data.onthenose])}>
                View All
              </button>
               )}
            </div>
          </div>
        </div>
      </div>
    </div>
      </div>
    </div>

    <div className="row">
    <div className="col-4 col-md-4 d-flex justify-content-center align-items-center">
          <div className="nose-container text-center">
              <img src={palate} alt="Nose Image" className="large-images mx-auto d-block" 
                  style={{ width: '80px', height: '80px', borderRadius: '56px' }}
              />
              <p className="text-center">On The Palate</p>
          </div>
      </div>
      <div className="col-8 col-md-8">
      <div className="flavours">
      <div className="circular-layout">
        <div className="im-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div className="row-container">
            <div className="im-container1" style={{textAlign:'center'}}>
            {hasOnThePalateFlavours ? (
                <div className="image-grid" style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gridGap: '4px'}}>
                  {apidata.data.onthepalate.slice(0, 6).map((aroma, index) => (
                    <div className="small-image-container" key={index}>
                      <img src={aroma.icon.preview_url} alt={`On the Palate Image ${index + 1}`} className="small-images" />
                    </div>
                  ))}
                  {apidata.data.onthepalate.length < 6 && apidata.data.randomonthepalate.slice(0, Math.max(0, 6 - apidata.data.onthepalate.length)).map((aroma, index) => (
                    <div className="small-image-container" key={index + apidata.data.onthepalate.length}>
                      <img src={aroma.icon.preview_url} alt={`On the Palate Image ${index + apidata.data.onthepalate.length + 1}`} className="small-images" />
                    </div>
                  ))}
                </div>
                  ) : (
                    <p className="text-center">No flavours selected</p>
              )}
               {apidata && apidata.data.onthenose.length > 1 && (
              <button className="smacid-btn v-all" 
               onClick={() => togglePopup(apidata.data.onthepalate)}>
                View All
              </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
      </div>
    </div>
   
   
  </div>

  {/* <div className="images-section">
   
   
  </div> */}
</div>

      <div className="flavour-content" style={{color:'rgb(10, 37, 60)',padding:'7px'}}>
      <b style={{textDecoration:'underline'}}>{apidata?apidata.data.report.whisky.whiskey_name:''}</b>
        <div className="report-content">
        <p>
            <span className="stars">{convertRatingToStars(apidata?apidata.data.report.ratings:'')}</span>
            <span style={{ color: 'black' }}>{apidata?apidata.data.report.ratings:''}.0</span>
            {/* <p>{apidata && apidata.data.report.review}</p> */}
            </p>
         
        </div>
      </div>
        <div className=" justify-content-center align-items-center mb-2 w-72">
          {hasAnyRating && (
        <div className="form-group align-items-center" style={{  height: 'auto', paddingRight: '10px', paddingLeft: '10px', paddingBottom: '10px', paddingTop: '10px', borderRadius: '20px', border: '2px solid black', marginTop:'-14px' }}>
          {hasOnTheNoseRating && (
            <>
              <div className="on_the_nose" style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '-25px', height: '37px' }}>
                <div className="on_the_nose_img">
                <h6 style={{ fontSize: '12px', fontWeight:'bold' ,marginTop:'8px'}}>Aromatics</h6>
                </div>
                <div className="on_the_nose_rating">
                  <p>
                    <span className="stars" style={{ fontSize: '16px' }}>
                      {convertRatingToStars(apidata.data.report.on_the_nose_rating)}
                    </span>
                    <span style={{ color: 'black', fontSize: '16px' }}>
                      {apidata.data.report.on_the_nose_rating}.0
                    </span>
                  </p>
                </div>
              </div>
              <hr />
            </>
          )}

          {hasOnThePalateRating && (
            <>
              <div className="on_the_nose" style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '-26px', marginTop: '-18px', height: '37px' }}>
                <div className="on_the_nose_img">
                <h6 style={{ fontSize: '12px', fontWeight:'bold' ,marginTop:'8px'}}>On the Palate</h6>
                  {/* <img src={palate} style={{ maxHeight: '23px', maxWidth: '23px' }} /> */}
                </div>
                <div className="on_the_nose_rating">
                  <p>
                    <span className="stars" style={{ fontSize: '16px' }}>
                      {convertRatingToStars(apidata.data.report.on_the_palate_ratings)}
                    </span>
                    <span style={{ color: 'black', fontSize: '16px' }}>
                      {apidata.data.report.on_the_palate_ratings}.0
                    </span>
                  </p>
                </div>
              </div>
              <hr />
            </>
          )}

          {hasFinishOption && (
            <>
              <div className="on_the_nose" style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '-13px', height: '38px',marginBottom:'-10px' }}>
                <div className="on_the_nose_img">
                <h6 style={{ fontSize: '12px', fontWeight:'bold' ,marginTop:'8px'}}>Finish</h6>
                  {/* <img src={finish} style={{ maxHeight: '23px', maxWidth: '23px' }} /> */}
                </div>
                <div className="on_the_nose_rating">
                  <p>
                    <span className="stars" style={{ fontSize: '12px' }}>
                      {convertRatingToStar(apidata.data.report.finish_option)}
                    </span>
                    <span style={{ color: 'black', fontSize: '12px' }}>
                      {apidata.data.report.finish_option}
                    </span>
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      )}
           {useReview ? <p className="description">{apidata && apidata.data.report.review}</p> : (
           <DescriptionComponent apidata={apidata} />
      )}
          
            <div className="checkbox-container d-flex mb-2 w-100" style={{ justifyContent: 'space-between',padding:'10px' }}>
      <div >
        <label className="mb-0 mr-2" htmlFor="useProfilePicture">
          <input
            type="checkbox"
            id="useProfilePicture"
            className="form-check-input mr-2"
            checked={useProfile}
            onChange={handleProfileCheckboxChange}
          />
          <span className="checkbox-text" style={{ fontSize: '14px' }}> 
             Use my Profile Image*
            <img
              src={note}
              style={{ width: '15px', marginLeft: '5px', cursor: 'pointer' }}
              onClick={handleProfileNote}
            />
          </span>
        </label>
        <Modal
        isOpen={showProfileNote}
        onRequestClose={closeModal}
        contentLabel="Profile Note"
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '300px',
            padding: '20px',
            borderRadius: '10px',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
          },
        }}
      >
        <button
      onClick={closeModal}
      style={{
        position: 'absolute',
        top: '10px',
        right: '10px',
        background: 'none',
        border: 'none',
        fontSize: '16px',
        cursor: 'pointer',
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <path
          fill="currentColor"
          d="M18.36 6.64a1 1 0 010 1.41L13.41 13l4.95 4.95a1 1 0 01-1.41 1.41L12 14.41l-4.95 4.95a1 1 0 01-1.41-1.41L10.59 13 5.64 8.05a1 1 0 011.41-1.41L12 11.59l4.95-4.95a1 1 0 011.41 0z"
        />
      </svg>
    </button>
        <b style={{ fontSize: '16px', marginBottom: '10px' }}>Profile Image Note</b>
        <p style={{ fontSize: '14px' }}>
          Your profile image will be used. If not selected, the Flavour Folio logo will be displayed instead.
        </p>
       
      </Modal>
        {/* {showProfileNote && (
          <div style={{ marginTop: '5px', fontSize: '12px', width: '190px' }}>
            Your profile image will be used. If not selected, the Flavour Folio logo will be displayed instead.
          </div>
        )} */}
      </div>
      
      <div >
        <label className="mb-0 mr-2" htmlFor="useReview">
          <input
            type="checkbox"
            id="useReview"
            className="form-check-input mr-2"
            checked={useReview}
            onChange={handleReview}
          />
          <span className="checkbox-text" style={{ fontSize: '14px' }}>
            Use my Notes
            <img
              src={note}
              style={{ width: '15px', marginLeft: '5px', cursor: 'pointer' }}
              onClick={handleReviewNote}
            />
          </span>
        </label>
        <Modal
        isOpen={showReviewNote}
        onRequestClose={closeReviewModal}
        contentLabel="Profile Note"
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '300px',
            padding: '20px',
            borderRadius: '10px',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
          },
        }}
      > <button
      onClick={closeReviewModal}
      style={{
        position: 'absolute',
        top: '10px',
        right: '10px',
        background: 'none',
        border: 'none',
        fontSize: '16px',
        cursor: 'pointer',
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <path
          fill="currentColor"
          d="M18.36 6.64a1 1 0 010 1.41L13.41 13l4.95 4.95a1 1 0 01-1.41 1.41L12 14.41l-4.95 4.95a1 1 0 01-1.41-1.41L10.59 13 5.64 8.05a1 1 0 011.41-1.41L12 11.59l4.95-4.95a1 1 0 011.41 0z"
        />
      </svg>
    </button>
        <b style={{ fontSize: '16px', marginBottom: '10px' }}>Review Note</b>
        <p style={{ fontSize: '14px' }}>
        Only your notes will be used instead of AI-generated ones. If no notes are entered, the result will be blank.        </p>
        
      </Modal>
        {/* {showReviewNote && (
          <div style={{ marginTop: '5px', fontSize: '12px', width: '160px' }}>
            Only your notes will be used instead of AI-generated ones. If no notes are entered, the result will be blank.
          </div>
        )} */}
      </div>
    </div>
        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        <div className="buttons" style={{ marginTop: '20px' }}>
  <button className="smacid-btn mb-2" style={{ float: 'left', width: '100%', height: '7vh', backgroundColor: '#0A253C', borderRadius: '12px' }} onClick={useDesignPage}>
    <b style={{ fontSize: '14px', textAlign: 'center', display: 'block' }}>Post My Review</b>
  </button>
  <button className="smacid-btn mb-3" style={{ float: 'right', width: '100%', height: '7vh', backgroundColor: 'white', borderRadius: '12px', boxShadow: 'none', border: '2px solid #0A253C' }} onClick={toggleColorOptions}>
    <b style={{ fontSize: '14px', color: '#0A253C', textAlign: 'center', display: 'block' }}>Change Design</b>
  </button>
</div>

    </div>
   {/* </div> */}
   </div>
}
<Modal
  isOpen={showPopup}
  onRequestClose={() => setShowPopup(false)}
  contentLabel="View All Popup"
  style={{
    content: {
      position: 'absolute', 
      height: 'fit-content',
      width: '80%',
      margin: 'auto',
      overflow: 'auto'
    }
  }}
>
  <button onClick={() => setShowPopup(false)} className="close-button">&times;</button>
  <div className="popup-grid">
    {popupData.map((item, index) => (
      <div key={index} className="popup-item">
        <img src={item.icon.url} alt={item.name} className="popup-image" />
        <p style={{ fontSize: '12px', fontWeight:'bold'}}>{item.name}</p>
      </div>
    ))}
  </div>
</Modal>

</div>

  );
};

export default Whisky;