import React, { useEffect, useState } from 'react';
import bgimg from '../../assets/assest/Background.png';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import backbutton from '../../assets/assest/back.jpeg';
import filter from '../../assets/assest/filter.png';
import nose from '../../assets/assest/nose.png';
import Modal from 'react-modal';
import palate from '../../assets/assest/palate.png'
import finish from '../../assets/assest/finish.png'
import feedback from '../../assets/assest/feedback.png'
import "./overallreview.css";

import Loader from "../loader/loader.js";

const OverallReview = () => {
  const location = useLocation();
  const navigate = useNavigate();
  // console.log("Location state:", location.state);
  const { whisky_id } = location.state;
  const [modalOpen, setModalOpen] = useState(false);
  const [apiData, setApiData] = useState(null);
  const [noseImages, setNoseImages] = useState([]);
  const [palateImages, setPalateImages] = useState([]);
  const [whiskeyId, setWhiskeyId] = useState('');
  const [selectedAroma, setSelectedAroma] = useState({ name: '', image: '' });
  const [isLoading, setIsLoading] = useState(true);  
  const [errorMessage, setErrorMessage] = useState('');
  const openModal = (name, image) => {
    setSelectedAroma({ name, image });
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const convertRatingToStars = (rating) => {
    const totalStars = 10;
    const filledStars = Math.round(rating);
    const starsArray = Array.from({ length: totalStars }, (v, i) =>
      i < filledStars ? '★' : '☆'
    );
    return starsArray.join('');
  };
  const handlePopstate = () => {
    window.history.back();
  };
  
  useEffect(() => {
    console.log("OverallReview whiskyid", whisky_id);
    window.scrollTo(0, 0);
    async function fetchData() {
      try {
        const formData = new FormData();
        formData.append('whiskey_id', whisky_id);

        const response = await fetch('https://admin.smacflavourfolio.com/api/user/getDetailedReportOfWhiskey', {
          method: "POST",
          body: formData,
        });

        const data = await response.json();
        if (data != null && !data.errors) {
          console.log("getDetailedReportOfWhiskey ", data);
          setApiData(data);
          if (data.data.noseImages && data.data.noseImages.length > 0) {
            setNoseImages(data.data.noseImages);
          }
          if (data.data.palateImages && data.data.palateImages.length > 0) {
            setPalateImages(data.data.palateImages);
          }
        } else {
          setErrorMessage(data.message || 'Failed to load data.');
        }
        setIsLoading(false);  // Set loading to false once data is fetched
      } catch (error) {
        console.error(error);
        setErrorMessage('Failed to load data.');
        setIsLoading(false);  // Set loading to false on error
      }
    }
    fetchData();
  }, [whisky_id]);

  if (isLoading) {
    return <Loader />;  // Show loader while loading
  }

  if (errorMessage) {
    return <div>{errorMessage}</div>;  // Show error message if data is not available
  }
  const handleBack = () => {
    window.history.back();
  };
  return (
    <div>
      
      {/* <div className="">
        <img src={bgimg} className="smac_img" alt="bg-img" />
      </div> */}
      <div className="whisky-header" style={{ position: 'relative' }}>
  <button
    type="button"
    className="btn-link"
    style={{
      color: 'white',
      border: 'none',
      background: 'transparent',
      cursor: 'pointer',
      position: 'absolute',
      left: '10px',
      top: '10px',
      zIndex: '1',

    }}
    onClick={handlePopstate}
  >
    <div className='gradient-border'>
      <img src={backbutton} style={{ cursor: 'pointer', width: '50px', height: '50px',borderRadius:'13px' }} alt="Back" />
    </div>
  </button>
  
  {apiData.data.whiskey.icon && (
    <img src={apiData.data.whiskey.icon.url} className="smac_img" alt={apiData.data.whiskey.whiskey_name} style={{ width: '100%', height: 'auto' }} />
  )}
</div>

 <div  className="container mt-4">
          <h5 className="d-flex justify-content-center align-items-center">
          <strong>
    <b>{apiData.data.whiskey.whiskey_name}</b>
  </strong>
           </h5>
           <p style={{textAlign:"center"}}> <span style={{ color: 'black', fontSize: '12px',fontWeight:'bold' }}>
                  Total Reviews:{apiData.data.totalReviewCount}
                </span>
                </p>
        </div>
      
      <div className="reports">
        <div className="image" style={{ backgroundColor: 'white', borderRadius: '22%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src={nose} alt="Image" style={{ width: '70px', height: '70px', padding: '10px' }} />
        </div>
        <div className="content-container" style={{ display: 'flex' }}>
          <div className="report-content" style={{ width: '250px', paddingLeft: '10px' }}>
            <span>
              <h6 style={{ fontWeight: 'bold' }}>Aromatics</h6>
              <p style={{ fontSize: '12px' }}>
                <span className="stars">
                  {convertRatingToStars(apiData.data.onTheNoseAvgRating)}
                </span>
                <span style={{ color: 'black', fontSize: '20px' }}>
                  {apiData.data.onTheNoseAvgRating}
                </span>
              </p>
            </span>
          </div>
        </div>
      </div>
      <div className="reports">
        <div className="image" style={{ backgroundColor: 'white', borderRadius: '22%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src={palate} alt="Image" style={{ width: '70px', height: '70px', padding: '10px' }} />
        </div>
        <div className="content-container" style={{ display: 'flex' }}>
          <div className="report-content" style={{ width: '250px', paddingLeft: '10px' }}>
            <span>
              <h6 style={{ fontWeight: 'bold' }}>On the Palate</h6>
              <p style={{ fontSize: '12px' }}>
                <span className="stars" style={{ fontSize: '20px' }}>
                  {convertRatingToStars(apiData.data.onThePalateAvgRating)}
                </span>
                <span style={{ color: 'black', fontSize: '20px' }}>
                  {apiData.data.onThePalateAvgRating}
                </span>
              </p>
            </span>
          </div>
        </div>
      </div>
      <div className="reports">
        <div className="image" style={{ backgroundColor: 'white', borderRadius: '22%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src={finish} alt="Image" style={{ width: '70px', height: '70px', padding: '10px' }} />
        </div>
        <div className="content-container" style={{ display: 'flex' }}>
          <div className="report-content" style={{ width: '207px', paddingLeft: '10px' }}>
            <span>
              <h6 style={{ fontWeight: 'bold' }}>Finish</h6>
              <p style={{ fontSize: '14px' }}>
                 {apiData.data.finish.map((element, index) => (
                    <span key={index}>{element==="null"?'':element+','} &nbsp;</span>
                   ))}
               </p>
            </span>
          </div>
        </div>
      </div>
      <div className="reports">
        <div className="image" style={{ backgroundColor: 'white', borderRadius: '22%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src={feedback} alt="Image" style={{ width: '70px', height: '70px', padding: '10px' }} />
        </div>
        <div className="content-container" style={{ display: 'flex' }}>
          <div className="report-content" style={{ width: '207px', paddingLeft: '10px' }}>
            <span>
              <h6 style={{ fontWeight: 'bold' }}>Overall whisky rating </h6>
              <p style={{ fontSize: '12px' }}>
              <p style={{ fontSize: '12px' }}>
                <span className="stars">
                {convertRatingToStars(apiData.data.overallrating)}
                </span>
                <span style={{ color: 'black', fontSize: '20px' }}>
                {apiData.data.overallrating}
                </span>
              </p>
              
              </p>
            </span>
          </div>
        </div>
      </div>
      <div className="aromas">
        <h6 style={{ fontWeight: 'bold' }}>Most Selected Aromas</h6>
        <div className="aromas-img">
        {noseImages && noseImages.map((image, index) => (
          <div
            key={index}
            className="image"
            onClick={() => openModal(image.name, image.icon.thumbnail)}
          >
            <img
              src={image.icon.thumbnail}
              alt={image.name}
            />
          </div>
        ))}
      </div>

        {modalOpen && (
          <div className="modal" style={{ display: 'block', position: 'fixed', zIndex: 1000, top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
            <div className="modal-content" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px', borderRadius: '13px', height: '28vh', width: '53%' }}>
              <span className="close" onClick={closeModal} style={{ position: 'absolute', top: '0px', right: '15px', cursor: 'pointer', fontSize: '23px', color: 'rgb(128 128 128 / 71%)' }}>&times;</span>
              <h6 style={{ fontWeight: 'bold', alignSelf: 'center', marginBottom: '20px' }}>{selectedAroma.name}</h6>
              <div className="image" style={{ backgroundColor: 'white', alignSelf: 'center', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', padding: '10px', borderRadius: '20px' }}>
                <img src={selectedAroma.image} alt="Selected Aroma" 
                // style={{ width: '60px', height: '60px' }} 
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="aromas">
        <h6 style={{ fontWeight: 'bold' }}>Most Selected Flavours</h6>
        <div className="aromas-img">
          {palateImages && palateImages.map((image, index) => (
            <div
              key={index}
              className="image"
              onClick={() => openModal(image.name, image.icon.thumbnail)}
            >
              <img
                src={image.icon.thumbnail}
                alt={image.name}
                
              />
            </div>
          ))}
        </div>
        {modalOpen && (
          <div className="modal" style={{ display: 'block', position: 'fixed', zIndex: 1000, top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
            <div className="modal-content" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px', borderRadius: '13px', height: '28vh', width: '53%' }}>
              <span className="close" onClick={closeModal} style={{ position: 'absolute', top: '0px', right: '15px', cursor: 'pointer', fontSize: '23px', color: 'rgb(128 128 128 / 71%)' }}>&times;</span>
              <h6 style={{ fontWeight: 'bold', alignSelf: 'center', marginBottom: '10px' }}>{selectedAroma.name}</h6>
              <div className="image"
               style={{width:'70%', backgroundColor: 'white', alignSelf: 'center', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', padding: '10px', borderRadius: '20px' }}
               >
                <img src={selectedAroma.image} alt="Selected Aroma" 
                 
                />
              </div>
            </div>
          </div>
        )}
      </div>
   {/* <Modal
  isOpen={modalOpen}
  onRequestClose={closeModal}
  contentLabel="Error Modal"
  style={{
    content: {
      height: '180px', 
      margin: 'auto',
      width: '80%', 
      textAlign: 'center' 
    }
  }}
>
  <div>
    <p>{errorMessage}</p>
    <button style={{ backgroundColor: '#0A253C' }} className="btn btn-primary" onClick={closeModal}>Close</button>
  </div>
</Modal> */}


    </div>
  );
};

export default OverallReview;
