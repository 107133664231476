import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "./loyalitypoints.css";
import profile from '../../assets/assest/profile icon.png';
import newmale from '../../assets/assest/member_level_frames/newbie.png'
// import newmale from '../../assets/assest/newbie.png';
import Loader from '../loader/loader';
import { useAuth } from '../Services/AuthContext';
import { useLocation } from 'react-router-dom';
import reward from '../../assets/assest/medal.png';
import backbutton from '../../assets/assest/back.jpeg';
import newfemale from '../../assets/assest/member_level_frames/newbie_female.png';
import moderatorMale from '../../assets/assest/member_level_frames/moderator_male.png';
import moderatorFemale from '../../assets/assest/member_level_frames/moderator_female.png';
import expertMale from '../../assets/assest/member_level_frames/expert_male.png';
import expertFemale from '../../assets/assest/member_level_frames/expert_female.png';
import connoisseurMale from '../../assets/assest/member_level_frames/connoisseur_male.png';
import connoisseurFemale from '../../assets/assest/member_level_frames/connoisseur_female.png';
import enthusiastMale from '../../assets/assest/member_level_frames/enthusiast_male.png';
import enthusiastFemale from '../../assets/assest/member_level_frames/enthusiast_female.png';


const LoyalityPoints = () => {
  const { logout, fetchProfile, profileData } = useAuth();
  const navigate = useNavigate();
  const [apiData, setApiData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const location = useLocation();
  const totalPointsEarned = profileData?.user_points.reduce((total, point) => total + point.points, 0) || 0;
  const shareCount = profileData?.reports.filter(report => report.type_id !== null).length || 0;

  useEffect(() => {
    setIsLoading(true);
    async function fetchData() {
      await fetchProfile();
      setIsLoading(false);
      console.log("profile data is", profileData);
    }
    fetchData();
  }, [location, fetchProfile]);

  const getYearFromCreatedAt = (createdAt) => {
    const date = new Date(createdAt);
    return date.getFullYear();
  };
  const handlePopstate = () => {
    window.history.back();
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    async function fetchData() {
      try {
        const response = await fetch('https://admin.smacflavourfolio.com/api/user/getUserPoints', {
          method: "GET",
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
          },
        });
        const data = await response.json();
        if (data && data.errors) {
          setErrorMessage(data.message);
        } else {
          setApiData(data);
          console.log("getUserPoints",data);
        }
      } catch (error) {
        console.error(error);
        setErrorMessage('Error fetching data');
      }
    }
    fetchData();
  }, []);

  if (isLoading) {
    return <Loader />;
  }
  const getNextLevel = (totalPointsEarned) => {
    if (totalPointsEarned >= 500) {
        return "Moderator";
    } else if (totalPointsEarned >= 301) {
        return "Expert";
    } else if (totalPointsEarned >= 151) {
        return "Connoisseur";
    } else if (totalPointsEarned >= 51) {
        return "Enthusiast";
    } else {
        return "Beginner";
    }
};
const getProfileImage = (gender, level) => {
  if (level === "Moderator") {
    return gender === "male" ? moderatorMale : moderatorFemale;
  } else if (level === "Expert") {
    return gender === "male" ? expertMale : expertFemale;
  } else if (level === "Connoisseur") {
    return gender === "male" ? connoisseurMale : connoisseurFemale;
  } else if (level === "Enthusiast") {
    return gender === "male" ? enthusiastMale : enthusiastFemale;
  } else {
    return gender === "male" ? newmale : newfemale;
  }
};
  return (
    <div className='container p-2'>
    <div className=" profileContainer1" style={{ fontFamily: 'Lato, sans-serif' }}>
     <div style={{ position: 'relative' }}>
  <button 
    type="button" 
    className="btn-link" 
    style={{ 
      color: "white", 
      border: 'none', 
      background: 'transparent', 
      cursor: 'pointer', 
      padding: 0, 
      margin: 0,
      position: 'relative', 
      zIndex: 10,
    }}
    onClick={handlePopstate}
  >
    <div className='gradient-border'>
    <img 
      src={backbutton} 
      style={{ 
        cursor: 'pointer', 
        width: '50px', 
        height: '50px', 
        borderRadius: '13px',
        display: 'block' 
      }} 
      alt="Back button"
    />
    </div>
  </button>
</div>
  <div className="whisky-club" style={{ display: 'flex', margin: '20px 30px', width: '85%', height: '121px', backgroundColor: 'white', borderRadius: '68px', border: '2px solid black', position: 'relative', zIndex: 1 }}>
    <div style={{ width: '42%' }}>
      {profileData && (
        <img
          src={getProfileImage(profileData.gender, getNextLevel(profileData.totalPointsEarned))}
          className="profile-pic"
          alt="profile-img"
      
          style={{ width: '125px', height: '130px',position:'absolute',top:'-6px',left:'-8px' }}
        />
      )}
      <img 
        src={profileData?.profile?.url || profile} 
        className="loyalitypoints-profile" 
        alt="profile-img" 
      />
    </div>
    <div className="user-info1" style={{ float: 'left' }}>
      <b>{profileData?.name || ''}</b><br />
      <hr />
      <b>On Flavour Folio since: : {profileData ? getYearFromCreatedAt(profileData.created_at) : '2024'}</b><br />
      <hr />
      <b>Reviews completed: {profileData?.reports.length || 0}</b><br />
    </div>
  </div>

  <div className='loyalty-points' style={{ marginTop: '20px' }}>
    <span>
      <h4 style={{ fontWeight: 'bold', margin: '0' }}>Loyalty Points</h4>
    </span>
    <p style={{ margin: '0', color: '#979D9D' }}>Usable: {'  '}<span style={{ fontWeight: 'bold', color: 'black' }}>{apiData?.total_points || 0} Points</span></p>
  </div>
</div>
      {errorMessage ? (
        <div style={{ textAlign: 'center', margin: '20px', color: '#979D9D' }}>
          {errorMessage}
        </div>
      ) : (
        apiData?.data ? (
          apiData.data.map(item => (
            <div className="reports" key={item.id}>
              <div className="image" style={{ backgroundColor: 'white', borderRadius: '22%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img src={reward} alt="Image" style={{ width: '70px', height: '70px', padding: '10px' }} />
              </div>
              <div className="content-container" style={{ display: 'flex' }}>
                <div className="report-content" style={{ paddingLeft: '10px' }}>
                  <span>
                    <h6 style={{ fontWeight: 'bold' }}>{item.name}</h6>
                    <p style={{ fontSize: '14px', width: '100%', color: '#979D9D' }}>
                      {item.title}
                    </p>
                  </span>
                </div>
                <div className="report-content" style={{width:'25%'}}>
                  <p style={{ margin: '0'}}>{item.points}Points</p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div style={{ textAlign: 'center', margin: '20px', color: '#979D9D' }}>
            No points data available.
          </div>
        )
      )}
    </div>
  );
};

export default LoyalityPoints;













































