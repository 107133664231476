import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "./dashboardn.css";
import exit from '../../assets/assest/logout.png';
import profile from '../../assets/assest/profile icon.png';
import contribute from '../../assets/assest/contibute.png'
import myreviews from '../../assets/assest/myreviews.png';
import addreview from '../../assets/assest/Add Review.png'
import loyalitypoints from '../../assets/assest/loyalty_points.png'
import edit from '../../assets/assest/pencil.jpeg'
import newmale from '../../assets/assest/member_level_frames/newbie.png'
import Loader from '../loader/loader';
import { useAuth } from '../Services/AuthContext';
import { useLocation } from 'react-router-dom';
// import newmale from '../../assets/assest/newbie.png';
import newfemale from '../../assets/assest/member_level_frames/newbie_female.png';
import moderatorMale from '../../assets/assest/member_level_frames/moderator_male.png';
import moderatorFemale from '../../assets/assest/member_level_frames/moderator_female.png';
import expertMale from '../../assets/assest/member_level_frames/expert_male.png';
import expertFemale from '../../assets/assest/member_level_frames/expert_female.png';
import connoisseurMale from '../../assets/assest/member_level_frames/connoisseur_male.png';
import connoisseurFemale from '../../assets/assest/member_level_frames/Connoisseur Female.png';
import enthusiastMale from '../../assets/assest/member_level_frames/enthusiast_male.png';
import enthusiastFemale from '../../assets/assest/member_level_frames/enthusiast_female.png';



const DashBoardn = () => {
const {logout,fetchProfile,profileData} =useAuth();
const navigate = useNavigate();
const [isLoading,setIsLoading] = useState(true);
const location = useLocation();
let updatedAt = profileData != null ? profileData.updated_at :null;
// let updatedAt=profileData != null ? profileData.updated_at.split(' ')[0] : null;
const totalPointsEarned = profileData?.user_points.reduce((total, point) => total + point.points, 0) || 0;
const shareCount = profileData?.reports.filter(report => report.type_id !== null).length || 0;

const goToProfilePage = () => {
  navigate("/profilepage", { state: { reports: profileData.reports} });
};

  const goToaddWhiskyForm= () => {
    navigate("/choosewhisky" );
  };
  const goToLoyaltyPoints= () => {
    navigate("/loyalitypoints");
  };
  const goTochoosewhisky = () => {
    navigate("/addWhiskyForm");
  };
  
  const getNextLevel = (totalPointsEarned) => {
    if (totalPointsEarned >= 500) {
        return "Moderator";
    } else if (totalPointsEarned >= 301) {
        return "Expert";
    } else if (totalPointsEarned >= 151) {
        return "Connoisseur";
    } else if (totalPointsEarned >= 51) {
        return "Enthusiast";
    } else {
        return "Beginner";
    }
};
const getProfileImage = (gender, level) => {
  if (level === "Moderator") {
    return gender === "male" ? moderatorMale : moderatorFemale;
  } else if (level === "Expert") {
    return gender === "male" ? expertMale : expertFemale;
  } else if (level === "Connoisseur") {
    return gender === "male" ? connoisseurMale : connoisseurFemale;
  } else if (level === "Enthusiast") {
    return gender === "male" ? enthusiastMale : enthusiastFemale;
  } else {
    return gender === "male" ? newmale : newfemale;
  }
};

  useEffect(()=>{
    setIsLoading(true);
    async function fetchData()
    {
      await fetchProfile();
      setIsLoading(false);
      console.log("profile data is",profileData);
    }
    fetchData();
  },[location, fetchProfile]);

  const getYearFromCreatedAt = (createdAt) => {
    const date = new Date(createdAt);
    return date.getFullYear();
  };

  const goLogin = () => {
    try{
      logout();
    }
    catch(e)
    {
      console.log("error",e);
    }
    navigate("/smacid");
  };

  const formatDate = (datetime) => {
    const date = new Date(datetime);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
  
    return `${day}-${month}-${year}`;
  };

  return (
    <div className="dashboardn-background">
    {isLoading ? (
      <Loader />
    ) : <div className="profileContainer">
      
    {/* <div className="whisky-club1">
        <div style={{width:'39%'}}> 
        {profileData && (
          <img
            src={getProfileImage(profileData.gender, getNextLevel(profileData.totalPointsEarned))}
            className="profile-pic"
            alt="profile-img"/>
        )}
        <img src={profileData?.profile?.url || profile} className="profile-image" alt="profile-img" />
        <div className="edit-container">
        <img
          src={edit}
          className="editicon"
          alt="edit"
          onClick={() => navigate('/editProfile')}
        />
        </div>
      </div>
      <div className="user-info1" style={{marginLeft:'90px'}}>
        <b>{profileData?.name || ''}</b><br />
        <hr />
        <b>On Flavour Folio since: {profileData ? getYearFromCreatedAt(profileData.created_at) : '2024'}</b><br />
        <hr />
        <b>Reviews completed: {profileData?.reports.length || 0}</b><br />
        <hr />
        <b>Last Login: {formatDate(updatedAt)}</b>
      </div>
    </div> */}


          
    <div className="whisky-club1">
      <div className='row'>

      <div className='col-5 profile-frame-container'>
      {/* Profile Image inside the Circular Frame */}
      <div className="profile-frame">
        {profileData && (
          <img
            src={getProfileImage(profileData.gender, getNextLevel(profileData.totalPointsEarned))}
            className="profile-pic"
            alt="profile-img"
          />
        )}
        <img
          src={profileData?.profile?.url || profile}
          className="profile-image"
          alt="profile-img"
        />
      </div>

      {/* Edit Icon */}
      <div className="edit-container">
        <img
          src={edit}
          className="editicon"
          alt="edit"
          onClick={() => navigate('/editProfile')}
        />
      </div>
    </div>
    
        {/* <div className='col-5'> 
        {profileData && (
          <img
            src={getProfileImage(profileData.gender, getNextLevel(profileData.totalPointsEarned))}
            className="profile-pic"
            alt="profile-img"/>
        )}
        <img src={profileData?.profile?.url || profile} className="profile-image" alt="profile-img" />
        <div className="edit-container">
        <img
          src={edit}
          className="editicon"
          alt="edit"
          onClick={() => navigate('/editProfile')}
        />
        </div>
      </div> */}
      <div className="col-7 user-info1">
        <b>{profileData?.name || ''}</b><br />
        <hr />
        <b>On Flavour Folio since: {profileData ? getYearFromCreatedAt(profileData.created_at) : '2024'}</b><br />
        <hr />
        <b>Reviews completed: {profileData?.reports.length || 0}</b><br />
        <hr />
        <b>Last Login: {formatDate(updatedAt)}</b>
      </div>
    </div>
    </div>
    
        {/* <div style={{width:'39%'}}> 
        {profileData && (
          <img
            src={getProfileImage(profileData.gender, getNextLevel(profileData.totalPointsEarned))}
            className="profile-pic"
            alt="profile-img"
            style={{ width: '235px', height: '235px', marginLeft: '-31px', marginTop: '-77px' }}
          />
        )}
        <img src={profileData!=null?profileData.profile?profileData.profile.url:profile:profile}className="profile-image" alt="profile-img" /> 
        <img
          src={edit}
          className="editicon"
          alt="edit"
          onClick={() => navigate('/editProfile')}
        />
        </div>

        <div className="user-info1" style={{ float: 'left',marginTop:'-16px'}}>
          <b>{profileData!=null?profileData.name:''}</b><br />
          <hr />
          <b>On Flavour Folio since:{profileData!=null?getYearFromCreatedAt(profileData.created_at):'2024'}</b><br/>
            <hr/>
            <b>Reviews completed: {profileData?.reports.length || 0}</b><br/>
            <hr/>
            <b>Last Login:{updatedAt} </b>
        </div>
        </div> */}
         
      
      <div className="whisky-club" style={{display:'flex',margin:'20px',borderRadius:'20px', backgroundColor:'#F7F9FD',padding:'10px',justifyContent:'center',border:'2px solid black',width:'88%'}}>
      <div className="user-info1 w-50">
    
         <b> Reviews: {profileData?.reports.length || 0}</b><br/>
          <hr/>
          <b>Votes received: 3</b><br/>
          <  hr/>
          <b>Votes given: 7</b><br/>
          <hr/>
          <b>Contributions: </b>
   
      </div>
      <div className="user-info1 w-50">
    
         <b>Shares on Social Media: {shareCount}</b><br/>
          <hr/>
          <b>Points Earned:{totalPointsEarned} </b><br/>
          <hr/>
          <b>Points Redeemed: 0</b><br/>
          <hr/>
          <b>Next Level: {getNextLevel(totalPointsEarned)}</b>
      </div>
      </div>
      <div className="container card-menu">
    <div className="row">
        {/* Contribute */}
        <div className="col-6 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center align-items-center card-menu-item card-menu-item-line" onClick={goTochoosewhisky}>
            <h4 className='text-center'>
              <div className='gradient-border'>
                <img className="img-fluid gradient-border-img" alt="" src={contribute}/>
              </div>
                <br/>
                <span className='icon-title'><b>Contribute</b></span>
            </h4>
        </div>
        {/* My Reviews */}
        <div className="col-6 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center align-items-center card-menu-item card-menu-item-line" onClick={goToProfilePage}>
            <h4 className='text-center'>
                <div className='gradient-border'>
                  <img className="img-fluid gradient-border-img" src={myreviews} alt=""/>
                </div>
                <br/>
                <span className='icon-title'><b>Reviews</b></span>
            </h4>
        </div>
    </div>
    <div className="row mt-2">
        {/* Add Review */}
        <div className="col-6 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center align-items-center card-menu-item card-menu-item-line" onClick={goToaddWhiskyForm}>
            <h4 className='text-center' >
              <div className='gradient-border'>
                <img className="img-fluid gradient-border-img" alt="" src={addreview}/>
              </div>
                <br/>
                <span className='icon-title'><b>Add Review</b></span>
            </h4>
        </div>
        {/* Loyalty Points */}
        <div className="col-6 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center align-items-center card-menu-item card-menu-item-line" onClick={goToLoyaltyPoints}>
            <h4 className='text-center'>
              <div className='gradient-border'>
                <img className="img-fluid gradient-border-img" src={loyalitypoints} alt=""/>
              </div>
                <br/>
                <span className='icon-title'><b>Loyalty Points</b></span>
            </h4>
        </div>
    </div>
    <div className='container'>
      <div className='row mt-3'>
        {/* Help And Support */}
        <div className='col-6 align-items-center'>
            <a href='https:www.smacindia.com' className='link'>Help & Support</a>
        </div>
            {/* Log Out */}
            <div className="col-6">
                <div className="justify-content-center" style={{float:'right'}} onClick={goLogin}>
                    {/* <img className="img-fluid back-logout" src={exit} alt="" /> */}
                    <button className="img-button gradient-border">
                      <img className="img-fluid back-logout gradient-border-img" src={exit} alt="" />
                    </button>
                    {/* <p className="title-two"><b>LOG OUT</b></p> */}
                </div>
            </div>
        </div>
    </div>
 
</div>

      </div>
      }
      </div>
  )}
  export default DashBoardn;