// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.update-btn{
   
    position: fixed;
    border-radius: 10px;
    background-color: #10263A;
    border: 2px solid #0A253C;
    right: 20%;
    left: 20%;
    font-weight: bold;
    color: white;
    height: 40px;
    
}

.profile-container {
    width: 100% !important;
}
.form-control1 {
    margin-left: -3px;
    width: 347px;
    height:53px;
    border-radius: 20px;
    box-shadow: inset 0 0 10px #00000029;
    border:none 
}
  .edit-icon1{
    position: relative;
    top: -116px;
    right: 27px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    border-radius: 50px;
  }
  .cropper-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  .cropper-crop-box {
    position: absolute;
    top: 26px;
    right: 117px;
  }
  
  .crop-container {
    position: relative;
    margin-top: 10px;
    width: 100%;
    height: 400px;
    background: #333;
  }
  
  .controls {
    margin-top: 20px;
    /* margin-left: 20px; */
  }
`, "",{"version":3,"sources":["webpack://./src/pages/editprofile/editprofile.css"],"names":[],"mappings":"AAAA;;IAEI,eAAe;IACf,mBAAmB;IACnB,yBAAyB;IACzB,yBAAyB;IACzB,UAAU;IACV,SAAS;IACT,iBAAiB;IACjB,YAAY;IACZ,YAAY;;AAEhB;;AAEA;IACI,sBAAsB;AAC1B;AACA;IACI,iBAAiB;IACjB,YAAY;IACZ,WAAW;IACX,mBAAmB;IACnB,oCAAoC;IACpC;AACJ;EACE;IACE,kBAAkB;IAClB,WAAW;IACX,WAAW;IACX,WAAW;IACX,YAAY;IACZ,eAAe;IACf,mBAAmB;EACrB;EACA;IACE,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,gBAAgB;EAClB;;EAEA;IACE,kBAAkB;IAClB,SAAS;IACT,YAAY;EACd;;EAEA;IACE,kBAAkB;IAClB,gBAAgB;IAChB,WAAW;IACX,aAAa;IACb,gBAAgB;EAClB;;EAEA;IACE,gBAAgB;IAChB,uBAAuB;EACzB","sourcesContent":[".update-btn{\n   \n    position: fixed;\n    border-radius: 10px;\n    background-color: #10263A;\n    border: 2px solid #0A253C;\n    right: 20%;\n    left: 20%;\n    font-weight: bold;\n    color: white;\n    height: 40px;\n    \n}\n\n.profile-container {\n    width: 100% !important;\n}\n.form-control1 {\n    margin-left: -3px;\n    width: 347px;\n    height:53px;\n    border-radius: 20px;\n    box-shadow: inset 0 0 10px #00000029;\n    border:none \n}\n  .edit-icon1{\n    position: relative;\n    top: -116px;\n    right: 27px;\n    width: 24px;\n    height: 24px;\n    cursor: pointer;\n    border-radius: 50px;\n  }\n  .cropper-container {\n    position: relative;\n    width: 100%;\n    height: 100%;\n    overflow: hidden;\n  }\n  \n  .cropper-crop-box {\n    position: absolute;\n    top: 26px;\n    right: 117px;\n  }\n  \n  .crop-container {\n    position: relative;\n    margin-top: 10px;\n    width: 100%;\n    height: 400px;\n    background: #333;\n  }\n  \n  .controls {\n    margin-top: 20px;\n    /* margin-left: 20px; */\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
