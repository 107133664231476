// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/assest/bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* body {
  margin: 0;
  font-family: 'Glacial Indifference', sans-serif;
  background-color: #fff0d9;
  overflow-y:scroll;
} */
body{
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
.bg{
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
@font-face {
  /* font-family: 'MyCustomFont'; */
  font-family: 'Lato', sans-serif !important;
  src: url('https://fonts.googleapis.com/css?family=Lato&display=swap');
}

code {
  font-family: 'Lato', sans-serif !important;
}

input[type="file"] {
  display: none;
}

.bg-color
{
  background-color: #F4F6FC;
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding-top: 15px;
  padding-bottom: 15px;
  cursor: pointer;
}

.addcheck{
  float: right;
}

.add{
  /* background-color: #c07f1e; */
  width: 100px;
  border: none;
  color: white;
  border: 2px solid #0A253C;
  /* padding: 12px 16px; */
  font-size: 16px;
  cursor: pointer;
  border-radius: 10px;
}

.form-control{
 box-shadow: 2px 2px 2px gray;
}


@media only screen and (min-width: 5391px ){
  
}
.input-shadow{
  box-shadow: 4px 4px 4px gray;
}
.btn-shadow{
  box-shadow: 0px 2px 10px rgb(168, 164, 164);
}`, "",{"version":3,"sources":["webpack://./src/pages/AddWhiskyForm/addWhiskyForm.css"],"names":[],"mappings":"AAAA;;;;;GAKG;AACH;EACE,yDAAmD;AACrD;AACA;EACE,yDAAmD;AACrD;AACA;EACE,iCAAiC;EACjC,0CAA0C;EAC1C,qEAAqE;AACvE;;AAEA;EACE,0CAA0C;AAC5C;;AAEA;EACE,aAAa;AACf;;AAEA;;EAEE,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;EACtB,qBAAqB;EACrB,iBAAiB;EACjB,oBAAoB;EACpB,eAAe;AACjB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,+BAA+B;EAC/B,YAAY;EACZ,YAAY;EACZ,YAAY;EACZ,yBAAyB;EACzB,wBAAwB;EACxB,eAAe;EACf,eAAe;EACf,mBAAmB;AACrB;;AAEA;CACC,4BAA4B;AAC7B;;;AAGA;;AAEA;AACA;EACE,4BAA4B;AAC9B;AACA;EACE,2CAA2C;AAC7C","sourcesContent":["/* body {\n  margin: 0;\n  font-family: 'Glacial Indifference', sans-serif;\n  background-color: #fff0d9;\n  overflow-y:scroll;\n} */\nbody{\n  background-image: url('../../assets/assest/bg.png');\n}\n.bg{\n  background-image: url('../../assets/assest/bg.png');\n}\n@font-face {\n  /* font-family: 'MyCustomFont'; */\n  font-family: 'Lato', sans-serif !important;\n  src: url('https://fonts.googleapis.com/css?family=Lato&display=swap');\n}\n\ncode {\n  font-family: 'Lato', sans-serif !important;\n}\n\ninput[type=\"file\"] {\n  display: none;\n}\n\n.bg-color\n{\n  background-color: #F4F6FC;\n}\n\n.custom-file-upload {\n  border: 1px solid #ccc;\n  display: inline-block;\n  padding-top: 15px;\n  padding-bottom: 15px;\n  cursor: pointer;\n}\n\n.addcheck{\n  float: right;\n}\n\n.add{\n  /* background-color: #c07f1e; */\n  width: 100px;\n  border: none;\n  color: white;\n  border: 2px solid #0A253C;\n  /* padding: 12px 16px; */\n  font-size: 16px;\n  cursor: pointer;\n  border-radius: 10px;\n}\n\n.form-control{\n box-shadow: 2px 2px 2px gray;\n}\n\n\n@media only screen and (min-width: 5391px ){\n  \n}\n.input-shadow{\n  box-shadow: 4px 4px 4px gray;\n}\n.btn-shadow{\n  box-shadow: 0px 2px 10px rgb(168, 164, 164);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
